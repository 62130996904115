import './Navmenu.css';
import React, { useState }  from 'react';
import { IonItem, IonLabel, useIonToast } from '@ionic/react';
import {
    IonContent,
    IonMenu,
    IonList,
    IonLoading
} from '@ionic/react';
import IonIcon from '@reacticons/ionicons';
import { useHistory } from 'react-router';
import { removeData } from '../../services/localstorage.service';
import { logout, logoutInterface } from '../../services/auth.service';

function Navmenu() {
    const [showLoading, setShowLoading] = useState(false);
    const [presentToast] = useIonToast();
    const presentToastr = (message: string, color: string) => {
        presentToast({
          message: message,
          duration: 3000,
          position: 'top',
          color: color
        });
      }
    
      const [logoutDetails] = useState<logoutInterface>({
        email: "",
        password: ""
      });

    const history = useHistory();
    const menuRef = React.useRef<HTMLIonMenuElement>(null);

      const handleLogout = async () => {
        let ans = window.confirm("Are you sure?");
        if (ans) {
          setShowLoading(true)
          const logoutRes = await logout(logoutDetails);
          setShowLoading(false);
          removeData('token');
          removeData('userprofile_details');
          removeData('aw');
          presentToastr('Logged out!', 'success');
          history.replace('/login');
        }
      }

    return (
        <>
          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Loading...'}
          />
            <IonMenu ref={menuRef} contentId="main-content" placeholder={undefined}>
                <IonItem className="ins-sidetopbar" placeholder={undefined}>
                    <IonLabel placeholder={undefined}>
                        <h1><b>Car360 evalutor</b></h1>
                        <p><b>sell or buy cars</b></p>
                    </IonLabel>
                </IonItem>
                <IonContent className="ion-padding side-bar" placeholder={undefined}>
                    <IonList lines="none" className='sidebar-menu' placeholder={undefined}>
                        <IonItem onClick={async () => { history.push('/cars'); menuRef.current?.close(); } } className="sidebar-list" placeholder={undefined}><IonIcon className="side-bar-icons" name="home"></IonIcon><IonLabel placeholder={undefined}>Home</IonLabel></IonItem>
                        <IonItem onClick={async () => { history.push('/profile'); menuRef.current?.close(); } } className="sidebar-list" placeholder={undefined}><IonIcon className="side-bar-icons" name="person-circle"></IonIcon><IonLabel placeholder={undefined}>Profile</IonLabel></IonItem>
                        <IonItem onClick={() => { history.push('/cars'); menuRef.current?.close(); } } className="sidebar-list" placeholder={undefined}><IonIcon className="side-bar-icons" name="car-sport"></IonIcon><IonLabel placeholder={undefined}>Car List</IonLabel></IonItem>
                        {/* <IonItem className="sidebar-list"><IonIcon className="side-bar-icons" name="newspaper-outline"></IonIcon><IonLabel>Terms & Conditions</IonLabel></IonItem> */}
                        {/* <IonItem className="sidebar-list"><IonIcon className="side-bar-icons" name="help-circle"></IonIcon><IonLabel>Need Help?</IonLabel></IonItem>
                        <IonItem className="sidebar-list"><IonIcon className="side-bar-icons" name="thumbs-up"></IonIcon><IonLabel>Rate Us</IonLabel></IonItem> */}
                        <IonItem onClick={() => { handleLogout(); menuRef.current?.close(); } } className="sidebar-list" placeholder={undefined}><IonIcon className="side-bar-icons" name="log-out"></IonIcon><IonLabel placeholder={undefined}>Logout</IonLabel></IonItem>
                    </IonList>
                </IonContent>
            </IonMenu>
        </>
    );
}
export default Navmenu;