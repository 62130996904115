import { ENV } from '../env';
import { getData } from './localstorage.service';

// Cars list APi Integration
export const carlist = async (page: number, inspector_id: number, search: string): Promise<any> => {
  let response = await fetch(ENV.endpoint + "car-list?page=" + page + "&per_page=" + ENV.per_page + "&inspector_id=" + inspector_id + "&registration_no=" + search, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    }
  });
  return response.json();
}

// Get car by id
export const carinfo = async (id: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "car-list?id=" + id, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    }
  });
  return response.json();
}

export const carbrands = async (): Promise<any> => {
  let response = await fetch(ENV.endpoint + "brand", {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    }
  });
  return response.json();
}

export const carmodels = async (brandid: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "car-model?brand_id=" + brandid, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    }
  });
  return response.json();
}

export const carvariants = async (modelid: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "varient?model_id=" + modelid, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    }
  });
  return response.json();
}

// startInspection API Integration

export const startCarsInspection = async (inspection: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "inspection-start", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(inspection)
  });
  return response.json();
}

// basicInfo API Integration

export const updatebasicinfo = async (basicinfo: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "basic-information/store", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(basicinfo)
  });
  return response.json();
}

// Fuel APi Integration

export const getfueltypes = async (): Promise<any> => {
  let response = await fetch(ENV.endpoint + "fuel", {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    }
  });
  return response.json();
}

// States APi Integration

export const getstates = async (): Promise<any> => {
  let response = await fetch(ENV.endpoint + "states?country_id="+ENV.country_id, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    }
  });
  return response.json();
}

// Cities APi Integration

export const getcities = async (state_id: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "cities?state_id="+state_id, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    }
  });
  return response.json();
}

// documents API Integration

export const updatedocuments = async (documents: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "documentation/store", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(documents)
  });
  return response.json();
}

// Rto listing API
export const listRto = async (): Promise<any> => {
  let response = await fetch(ENV.endpoint + "rto-list?per_page=100000", {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
  });
  return response.json();
}

// Exterior Info API Integration

export const updateexterior = async (exterior: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "exterior/store", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(exterior)
  });
  return response.json();
}

//Tyres API Integration
export const updatetyres = async (tyres: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "exterior-tyre/store", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(tyres)
  });
  return response.json();
}

//WindShield and Light API Integration
export const updatewindshield = async (windshield: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "windshield-light/store", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(windshield)
  })
  return response.json();
}

//Engine API Integration
export const vehicleengine = async (engine: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "engine/store", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(engine)
  })
  return response.json();
}

//AC & Heater API Integration
export const AcHeater = async (AC: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "ac-and-heater/store", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(AC)
  })
  return response.json();
}

//Electrical API Integration
export const updateelectrical = async (electrical: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "electrical/store", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(electrical)
  })
  return response.json();
}

//Steering API Integration
export const updatesteering = async (steering: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "steering-and-suspension/store", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(steering)
  })
  return response.json();
}

// Car Notification API Integration
export const updatenotification = async (notification: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "notifications", {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
  })
  return response.json();
}

// Car Notification Delete API Integration
export const deletenotification = async (id: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "notifications/delete", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(id)
  })
  return response.json();
}

//complete process
export const completeprocess = async (data: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "complete-process/store", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(data)
  })
  return response.json();
}

//submit interior form
export const interiorSubmit = async (data: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "interior/store", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(data)
  })
  return response.json();
}