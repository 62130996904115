import './Interior.css';
import {
    IonCard,
    IonPage,
    IonContent,
    IonCardHeader,
    IonCardTitle,
    IonSelectOption,
    IonSelect,
    IonButton,
    useIonToast,
    IonLoading,
    useIonViewDidEnter
} from '@ionic/react';
import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import IonIcon from '@reacticons/ionicons';
import { useHistory } from 'react-router';
import { getQueryParams } from '../../services/helper.service';
import { interiorSubmit, carinfo } from '../../services/cars.service';
import { useState } from 'react';
import { getData } from '../../services/localstorage.service';
import { imagelistingbyscreen } from '../../services/camera.service';
import { takePicture, uploadimage } from '../../services/camera.service';

function Interior() {
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);
    const [presentToast] = useIonToast();

    const presentToastr = (message: string, color: string) => {
        presentToast({
            message: message,
            duration: 3000,
            position: 'top',
            color: color
        });
    }

    const [interiorData, setInteriorData] = useState<any>({
        'id': '',
        'car_id': '',
        'lead_id': getQueryParams('id'),
        'inspector_id': '',
        'front_right': 'Ok',
        'back_right': 'Ok',
        'back_seat': 'Ok',
        'dashboard': '',
        'interior_completed': '1',
    });

    let [imagesObj, setImagesObj] = useState<any>({
        'int_dashboard_1_image': 'https://ionicframework.com/docs/img/demos/card-media.png',
        'int_dashboard_2_image': 'https://ionicframework.com/docs/img/demos/card-media.png',
        'int_dashboard_3_image': 'https://ionicframework.com/docs/img/demos/card-media.png',
        'int_dashboard_4_image': 'https://ionicframework.com/docs/img/demos/card-media.png',
        'int_front_right_image': 'https://ionicframework.com/docs/img/demos/card-media.png',
        'int_back_right_image': 'https://ionicframework.com/docs/img/demos/card-media.png',
        'int_back_seat_image': 'https://ionicframework.com/docs/img/demos/card-media.png'
    });

    let listImages = () => {
        imagelistingbyscreen({
            'screen_type': 'interior',
            "car_id": getQueryParams('cid'),
            'lead_id': getQueryParams('id')
        }).then(images => {
            if (images.data.length > 0) {
                let imageMap: any = {};
                images.data.map((image: any) => {
                    imageMap[image.image_type] = image.path;
                })
                setImagesObj({
                    ...imagesObj,
                    ...imageMap
                });
            }
        });
    }

    const handleImageUpload = async (imageType: string, edit: boolean = false) => {
        let imgData = await takePicture(edit);
        if (imgData) {
            setShowLoading(true);
            await uploadimage({
                "car_id": getQueryParams('cid'),
                "lead_id": getQueryParams('id'),
                [imageType]: imgData
            });
            setShowLoading(false);
        }
        listImages();
    };

    const handleInputs = async (e: any) => {
        let inputObj = {
            "name": e.target.name,
            "value": e.target.value
        };
        setInteriorData({
            ...interiorData,
            [inputObj.name]: inputObj.value
        });
    }

    const getCarinfo = async () => {
        let carData = await carinfo(getQueryParams('id'));
        if (carData.success) {
            if (carData.data.data.length == 0) {
                presentToastr('car not found', 'danger');
                history.goBack();
            };
            let interior = carData.data.data[0].cars.interior
            let inspectorData = JSON.parse(getData('userprofile_details'));
            setInteriorData({
                ...interiorData,
                car_id: carData.data.data[0].cars.id,
                inspector_id: inspectorData.id
            })
            if (interior != null) {
                setInteriorData({
                    ...interiorData,
                    'id': interior.id,
                    'car_id': carData.data.data[0].cars.id,
                    'lead_id': getQueryParams('id'),
                    'inspector_id': inspectorData.id,
                    'front_right': interior.front_right,
                    'back_right': interior.back_right,
                    'back_seat': interior.back_seat,
                    'dashboard': interior.dashboard
                })
            }
        }
    }

    useIonViewDidEnter(async () => {
        setShowLoading(true);
        await getCarinfo();
        setShowLoading(false);
        listImages();
    });

    const submitInterior = async () => {
        setShowLoading(true);
        let interiorInfo = await interiorSubmit(interiorData);
        setShowLoading(false);
        if (interiorInfo.success) {
            presentToastr(interiorInfo.message, 'success');
            history.replace('/carsinfo?id=' + getQueryParams('id'));
        } else {
            presentToastr(interiorInfo.message, 'danger');
        }
    }

    return (
        <div>
            <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header pageTitle="Interior" />
                <IonContent className="ion-padding" placeholder={undefined}>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                    />
                    <h2>Interior Front Right Image</h2>
                    
                    <IonCard placeholder={undefined}>
                        <img alt="carprofile" src={imagesObj.int_front_right_image} onClick={async () => { await handleImageUpload('int_front_right_image') }} />
                    </IonCard>

                    <h2>Interior Back Right Image</h2>
                    
                    <IonCard placeholder={undefined}>
                        <img alt="carprofile" src={imagesObj.int_back_right_image} onClick={async () => { await handleImageUpload('int_back_right_image') }} />
                    </IonCard>

                    <h2>Interior Back Seat Image</h2>
                    
                    <IonCard placeholder={undefined}>
                        <img alt="carprofile" src={imagesObj.int_back_seat_image} onClick={async () => { await handleImageUpload('int_back_seat_image') }} />
                    </IonCard>

                    <h2>Dashboard Images</h2>

                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Dashboard</IonCardTitle></IonCardHeader>
                                <IonSelect placeholder="ok" name="dashboard" value={interiorData.dashboard} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Broken" placeholder={undefined}>Broken</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                </IonSelect>
                            </div>
                        </div>
                    </IonCard>
    
                    <h2>Dashboard Images</h2>
                    
                    <IonCard placeholder={undefined}>
                        <img alt="carprofile" src={imagesObj.int_dashboard_1_image} onClick={async () => { await handleImageUpload('int_dashboard_1_image') }} />
                    </IonCard>
                    <IonCard placeholder={undefined}>
                        <img alt="carprofile" src={imagesObj.int_dashboard_2_image} onClick={async () => { await handleImageUpload('int_dashboard_2_image') }} />
                    </IonCard>
                    <IonCard placeholder={undefined}>
                        <img alt="carprofile" src={imagesObj.int_dashboard_3_image} onClick={async () => { await handleImageUpload('int_dashboard_3_image') }} />
                    </IonCard>
                    <IonCard placeholder={undefined}>
                        <img alt="carprofile" src={imagesObj.int_dashboard_4_image} onClick={async () => { await handleImageUpload('int_dashboard_4_image') }} />
                    </IonCard>
                </IonContent>
                <div className="ac-txt">
                    <IonButton onClick={async () => { await submitInterior(); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
                </div>
            </IonPage>
        </div>
    )
}

export default Interior
