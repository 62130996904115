import { useState } from 'react'
import './Basicinfo.css'
import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import { IonCardContent, IonCardHeader, IonCard, IonCardTitle, IonPage, IonContent, useIonViewDidEnter, IonLoading, useIonToast } from '@ionic/react';
import { IonItem, IonList, IonTextarea, IonSelect, IonSelectOption, IonInput } from '@ionic/react';
import IonIcon from '@reacticons/ionicons';
import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import { carbrands, carmodels, carvariants, updatebasicinfo } from '../../services/cars.service';
import { getQueryParams } from '../../services/helper.service';
import { carinfo, getfueltypes, getstates, getcities } from '../../services/cars.service';
import { imagelistingbyscreen } from '../../services/camera.service';
import { takePicture, uploadimage } from '../../services/camera.service';
import { format, addYears } from 'date-fns';
import { getData } from '../../services/localstorage.service';
import { ENV } from '../../env';

function Carmodal() {
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);
    const [presentToast] = useIonToast();

    const presentToastr = (message: string, color: string) => {
        presentToast({
            message: message,
            duration: 3000,
            position: 'top',
            color: color
        });
    }

    let userData: any = JSON.parse(getData('userprofile_details'));

    const [basicInfo, setBasicInfo] = useState<any>({
        id: getQueryParams('id'),
        name: "",
        phone: "",
        address: "",
        state_id: "",
        city_id: "",
        country_id: ENV.country_id,
        brand_id: "",
        model_id: "",
        varient_id: "",
        make_year: "",
        mfg_date: "",
        registration_no: "",
        registration_date: "",
        expiration_date: "",
        mfg_month: "",
        fuel_id: "",
        color: "",
        car_mileage: "",
        owner_serial: "",
        transmission_type: "",
        vehical_condition: "",
        comments: "",
        inspector_id: userData.id,
        basic_info_completed: "1"
    });
    const [brands, setBrands] = useState<any[]>([]);
    const [models, setModels] = useState<any[]>([]);
    const [variants, setVariants] = useState<any[]>([]);
    const [fueltypes, setFuelTypes] = useState<any[]>([]);
    const [states, setStates] = useState<any[]>([]);
    const [cities, setCities] = useState<any[]>([]);

    const [mfYear, setMfyear] = useState<any[]>([]);

    let [imagesObj, setImagesObj] = useState<any>({
        'car_mileage_image': null
    });

    let listImages = () => {
        imagelistingbyscreen({
            'screen_type': 'basic-information',
            "car_id": getQueryParams('cid'),
            'lead_id': getQueryParams('id')
        }).then(images => {
            if (images.data.length > 0) {
                let imageMap: any = {};
                images.data.map((image: any) => {
                    imageMap[image.image_type] = image.path;
                })
                setImagesObj({
                    ...imagesObj,
                    ...imageMap
                });
            }
        });
    }

    const handleImageUpload = async (imageType: string, edit: boolean = false) => {
        let imgData = await takePicture(edit);
        if (imgData) {
            setShowLoading(true);
            await uploadimage({
                "car_id": getQueryParams('cid'),
                "lead_id": getQueryParams('id'),
                [imageType]: imgData
            });
            setShowLoading(false);
        }
        listImages();
    };

    const handleInputs = async (e: any) => {
        let inputObj = {
            "name": e.target.name,
            "value": e.target.value
        };
        if (e.target.name == "registration_no") {
            inputObj.value = e.target.value.toUpperCase().trim();
        }
        if (e.target.name == 'brand_id') {
            await brandOnChange(e.target.value);
        }
        if (e.target.name == 'model_id') {
            await modelOnChange(e.target.value);
        }
        if (e.target.name == 'state_id') {
            await stateOnChange(e.target.value);
        }
        if (e.target.name == "registration_date") {
            let addYearsDate = format(addYears(new Date(inputObj.value), 15), 'yyyy-MM-dd');
            setBasicInfo({
                ...basicInfo,
                [inputObj.name]: inputObj.value,
                'expiration_date': addYearsDate
            });
        } else {
            setBasicInfo({
                ...basicInfo,
                [inputObj.name]: inputObj.value
            });
        }
    }

    const brandOnChange = async (brand_id: any) => {
        let modelsData: any = await carmodels(brand_id);
        setModels(modelsData.data);
    }

    const modelOnChange = async (model_id: any) => {
        let variantsData: any = await carvariants(model_id);
        setVariants(variantsData.data);
    }

    const stateOnChange = async (state_id: any) => {
        let citiesData: any = await getcities(state_id);
        setCities(citiesData.data);
    }

    const submitBasicInfo = async () => {
        setShowLoading(true);
        let updateBi = await updatebasicinfo(basicInfo);
        setShowLoading(false);
        if (updateBi.success) {
            presentToastr(updateBi.message, 'success');
            history.replace('/carsinfo?id=' + updateBi.data.lead_id);
        } else {
            presentToastr(updateBi.message, 'danger');
        }
    }

    const getCarinfo = async () => {
        if (getQueryParams('id') == 0) {
            return false;
        }
        let carData: any = await carinfo(getQueryParams('id'));
        if (carData.success) {
            if (carData.data.data.length == 0) {
                presentToastr('Car not found', 'danger');
                history.goBack();
            }
            let car = carData.data.data[0].cars;

            if (car.brand_id != "") {
                await brandOnChange(car.brand_id);
                if (car.model_id != "") {
                    await modelOnChange(car.model_id);
                }
            }

            if (car.state_id != "") {
                await stateOnChange(car.state_id);
            }

            setBasicInfo({
                ...basicInfo,
                id: car.id,
                name: carData.data.data[0].user.name,
                phone: carData.data.data[0].user.phone,
                address: car.address,
                state_id: car.state_id,
                city_id: car.city_id,
                make_year: car.make_year,
                mfg_date: car.mfg_date,
                registration_no: car.registration_no,
                registration_date: car.registration_date,
                expiration_date: car.expiration_date,
                mfg_month: car.mfg_month,
                fuel_id: car.fuel_id,
                color: car.color,
                car_mileage: car.car_mileage,
                owner_serial: car.owner_serial?.toString(),
                transmission_type: car.transmission_type,
                vehical_condition: car.vehical_condition,
                brand_id: car.brand_id,
                model_id: car.model_id,
                varient_id: car.varient_id,
                comments: car.comments
            });
        }
    }

    useIonViewDidEnter(async () => {
        setShowLoading(true);
        let brandsData: any = await carbrands();
        let fuelData: any = await getfueltypes();
        let stateData: any = await getstates();
        setBrands(brandsData.data);
        setFuelTypes(fuelData.data);
        setStates(stateData.data);
        await getCarinfo();
        setShowLoading(false);
        listImages();

        let year = [];
        for (let index = new Date().getFullYear(); index >= 2012; index --){
            year.push(index);
        }
        setMfyear(year)
    });

    const getMonthOptions = () => {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
    
        return months.map((month, index) => (
            <option key={index} value={index + 1}>
                {month}
            </option>
        ));
    };

    return (
        <>
            <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header pageTitle="Basic Information" />
                <IonContent className="ion-padding" placeholder={undefined}>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Name</IonCardTitle></IonCardHeader>
                                <IonInput name="name" type="text" placeholder="Name" className="innerTextStyle" value={basicInfo.name} onIonChange={(event: any) => { handleInputs(event) }}></IonInput>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Phone Number</IonCardTitle></IonCardHeader>
                                <IonInput name="phone" type="text" placeholder="Phone No" className="innerTextStyle" value={basicInfo.phone} onIonChange={(event: any) => { handleInputs(event) }}></IonInput>
                            </div>
                        </div>
                    </IonCard>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>State</IonCardTitle></IonCardHeader>
                                <select className="dropdown-icon custom-select" name="state_id" value={basicInfo.state_id} onChange={(event: any) => { handleInputs(event) }}>
                                    <option value="" className="innerTextStyle">Select State</option>
                                    {states.map(stateRec => {
                                        return (<option key={stateRec.id} value={stateRec.id} className="innerTextStyle">{stateRec.name}</option>)
                                    })}
                                </select>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>City</IonCardTitle></IonCardHeader>
                                <select name="city_id" className="custom-select" value={basicInfo.city_id} onChange={(event: any) => { handleInputs(event) }}>
                                    <option value="">Select City</option>
                                    {cities.map(city => {
                                        return (<option key={city.id} value={city.id}>{city.name}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                    </IonCard>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <div>
                            <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Address</IonCardTitle></IonCardHeader>
                            <IonTextarea name="address" placeholder="Type something here" value={basicInfo.address} onIonChange={(event: any) => { handleInputs(event) }}></IonTextarea>
                        </div>
                    </IonCard>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <IonLoading
                            cssClass='my-custom-class'
                            isOpen={showLoading}
                            onDidDismiss={() => setShowLoading(false)}
                            message={'Loading...'}
                        />
                        <IonCardHeader placeholder={undefined}>
                            <IonCardTitle placeholder={undefined}>Car Maker</IonCardTitle>
                        </IonCardHeader>
                        <IonList className="modals-list" placeholder={undefined}>
                            <IonItem placeholder={undefined}>
                                <IonCardContent className="modal-srch" placeholder={undefined}>
                                    <select name="brand_id" className="custom-select" value={basicInfo.brand_id} onChange={(event: any) => { handleInputs(event) }}>
                                        <option value="">Select Make</option>
                                        {brands.map(brand => {
                                            return (<option key={brand.id} value={brand.id}>{brand.name}</option>)
                                        })}
                                    </select>
                                </IonCardContent>
                            </IonItem>
                        </IonList>
                    </IonCard>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Car Model</IonCardTitle></IonCardHeader>
                        <IonList className="modals-list" placeholder={undefined}>
                            <IonItem placeholder={undefined}>
                                <IonCardContent className="modal-srch" placeholder={undefined}>
                                    <select name="model_id" className="custom-select" value={basicInfo.model_id} onChange={(event: any) => { handleInputs(event) }}>
                                        <option value="">Select Model</option>
                                        {models.map(model => {
                                            return (<option key={model.id} value={model.id}>{model.name}</option>)
                                        })}
                                    </select>
                                </IonCardContent>
                            </IonItem>
                        </IonList>
                    </IonCard>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Car Varient</IonCardTitle></IonCardHeader>
                        <IonList className="modals-list" placeholder={undefined}>
                            <IonItem placeholder={undefined}>
                                <IonCardContent className="modal-srch" placeholder={undefined}>
                                    <select name="varient_id" className="custom-select" value={basicInfo.varient_id} onChange={(event: any) => { handleInputs(event) }}>
                                        <option value="">Select Varient</option>
                                        {variants.map(variant => {
                                            return (<option key={variant.id} value={variant.id}>{variant.name}</option>)
                                        })}
                                    </select>
                                </IonCardContent>
                            </IonItem>
                        </IonList>
                    </IonCard>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Mfg Year</IonCardTitle></IonCardHeader>
                                {/* <IonInput name="make_year" type="date" placeholder="yyyy" value={basicInfo.make_year} onIonChange={(event: any) => { handleInputs(event) }}></IonInput> */}
                                <IonList className="modals-list" placeholder={undefined}>
                                <IonItem placeholder={undefined}>
                                    <IonCardContent className="modal-srch" placeholder={undefined}>
                                        <select name="make_year" className="custom-select" value={basicInfo.make_year} onChange={(event: any) => { handleInputs(event) }}>
                                            <option value="">Select Mfg Year</option>
                                            {mfYear.map(yearData => {
                                                return (<option key={yearData.id} value={yearData.id}>{yearData}</option>)
                                            })}
                                        </select>
                                    </IonCardContent>
                                </IonItem>
                            </IonList>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Reg. Number</IonCardTitle></IonCardHeader>
                                <IonInput name="registration_no" type="text" placeholder="Enter Reg No" value={basicInfo.registration_no} onIonChange={(event: any) => { handleInputs(event) }}></IonInput>
                            </div>
                        </div>
                    </IonCard>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Date of Reg..</IonCardTitle></IonCardHeader>
                                <IonInput name="registration_date" type="date" placeholder="yyyy" value={basicInfo.registration_date} onIonChange={(event: any) => { handleInputs(event) }}></IonInput>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Date of Expire</IonCardTitle></IonCardHeader>
                                <IonInput name="expiration_date" type="date" placeholder="yyyy" value={basicInfo.expiration_date} onIonChange={(event: any) => { handleInputs(event) }}></IonInput>
                            </div>
                        </div>
                    </IonCard>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            {/* <div>
                                <IonCardHeader><IonCardTitle>Mfg Date</IonCardTitle></IonCardHeader>
                                <IonInput name="mfg_date" type="text" placeholder="Mfg Date" value={basicInfo.mfg_date} onIonChange={(event: any)=>{handleInputs(event)}}></IonInput>
                                </div> */}
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Mfg Month</IonCardTitle></IonCardHeader>
                                <IonList className="modals-list" placeholder={undefined}>
                                <IonItem placeholder={undefined}>
                                    <IonCardContent className="modal-srch" placeholder={undefined}>
                                        <select name="mfg_month" className="custom-select" value={basicInfo.mfg_month} onChange={(event: any) => { handleInputs(event) }}>
                                            <option value="">Select Mfg Month</option>
                                            {getMonthOptions()}
                                        </select>
                                    </IonCardContent>
                                </IonItem>
                            </IonList>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Fuel Type</IonCardTitle></IonCardHeader>
                                <div className='mfgyear-icon'><IonIcon name="calendar" />
                                    <select name="fuel_id" className="custom-select" value={basicInfo.fuel_id} onChange={(event: any) => { handleInputs(event) }}>
                                        <option value="">Select Fuel</option>
                                        {fueltypes.map(fuel => {
                                            return (<option key={fuel.id} value={fuel.id}>{fuel.name}</option>)
                                        })}
                                    </select></div>
                            </div>
                        </div>
                    </IonCard>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Owner Serial</IonCardTitle></IonCardHeader>
                                <IonSelect name="owner_serial" placeholder="select option" value={basicInfo.owner_serial} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption value="1" placeholder={undefined}>1st Owner</IonSelectOption>
                                    <IonSelectOption value="2" placeholder={undefined}>2nd Owner</IonSelectOption>
                                    <IonSelectOption value="3" placeholder={undefined}>3rd Owner</IonSelectOption>
                                    <IonSelectOption value="4" placeholder={undefined}>4th Owner</IonSelectOption>
                                    <IonSelectOption value="5" placeholder={undefined}>5th Owner</IonSelectOption>
                                </IonSelect>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Transmission Type</IonCardTitle></IonCardHeader>
                                <IonSelect className="dropdown-icon" name="transmission_type" placeholder="select option" value={basicInfo.transmission_type} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption value="automatic" placeholder={undefined}>Automatic</IonSelectOption>
                                    <IonSelectOption value="manual" placeholder={undefined}>Manual</IonSelectOption>
                                </IonSelect>
                            </div>
                        </div>
                    </IonCard>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <div className='veh-color'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Color</IonCardTitle></IonCardHeader>
                                <IonItem placeholder={undefined}>
                                    <IonInput type="text" name="color" placeholder="Color" value={basicInfo.color} onIonChange={(event: any) => { handleInputs(event) }}></IonInput>
                                </IonItem>
                            </div>
                            <div>
                                <IonCardHeader className="cam-iconss" placeholder={undefined}><IonCardTitle placeholder={undefined}>Car Mileage</IonCardTitle>
                                    {/* {getQueryParams('id') != 0 &&
                                        <IonIcon className={(imagesObj.car_mileage_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} name="camera" onClick={() => { handleImageUpload('car_mileage_image') }}></IonIcon>
                                    } */}
                                </IonCardHeader>
                                <IonItem placeholder={undefined}>
                                    <IonInput type="number" name="car_mileage" placeholder="Kilometre" value={basicInfo.car_mileage} onIonChange={(event: any) => { handleInputs(event) }}></IonInput>
                                </IonItem>
                            </div>
                        </div>
                    </IonCard>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <IonCardHeader placeholder={undefined}>
                            <IonCardTitle placeholder={undefined}>Vehicle Condition</IonCardTitle>
                        </IonCardHeader>
                        <IonList className="modals-list" placeholder={undefined}>
                            <IonItem placeholder={undefined}>
                                <IonCardContent className="modal-srch" placeholder={undefined}>
                                    <IonSelect name="vehical_condition" placeholder="select option" value={basicInfo.vehical_condition} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                        <IonSelectOption value="normal" placeholder={undefined}>Normal</IonSelectOption>
                                        <IonSelectOption value="scrap" placeholder={undefined}>Scrap</IonSelectOption>
                                    </IonSelect>
                                </IonCardContent>
                            </IonItem>
                        </IonList>
                    </IonCard>
                    <div className="modal-txtarea">
                        <IonTextarea name="comments" placeholder="Type something here" value={basicInfo.comments} onIonChange={(event: any) => { handleInputs(event) }}></IonTextarea>
                    </div>
                    <IonButton onClick={async () => { await submitBasicInfo(); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
                </IonContent>
            </IonPage>
        </>
    )
}

export default Carmodal
