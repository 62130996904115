import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Splash from './splash/Splash';
import Login from './auth/login/Login';
import Forgetpswd from './auth/forgot/Forgetpswd';
import Resetpassword from './auth/reset/Resetpassword';
import Register from './auth/register/Register';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../theme/variables.css';
import './App.css';
import Cars from './cars/Cars';
import Insreport from './insreport/Insreport';
import Carsinfo from './carsinfo/Carsinfo';
import Basicinfo from './basicinfo/Basicinfo';
import Doc from './documentation/Doc';
import Exterior from './exterior/Exterior';
import Tyres from './tyres/Tyres'
import Windshield from './windshield/Windshield';
import Engine from './engine/Engine';
import Ac from './ac/Ac';
import Electrical from './electrical/Electrical';
import Steering from './steering/Steering';
import Carprofile from './carprofile/Carprofile';
import Profile from './profile/Profile';
import Notifications from './notification/Notifications';
import Termsandcondition from './termsandcondition/Termsandcondition';
import { InspectionImageContextComponent } from '../components/context/inspectionimagecontext';
import { Capacitor } from "@capacitor/core";
import { App as Capapp } from '@capacitor/app';
import Interior from './interior/Interior';
import Uploadedimages from './uploadedimages/Uploadedimages';
import Sellerdocs from './sellerdocs/Sellerdocs';

setupIonicReact();
const App: React.FC = () => {

  if (Capacitor.isNativePlatform()) {
    Capapp.addListener("backButton", (e) => {
      if (window.location.pathname === "/" || window.location.pathname === "/login" || window.location.pathname === "/cars") {
        // Show A Confirm Box For User to exit app or not
        let ans = window.confirm("Are you sure you want to exit?");
        if (ans) {
          Capapp.exitApp();
        }
      }
    });
  }

  return (
    <InspectionImageContextComponent>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet placeholder={undefined}>
            <Route exact path="/">
              <Splash />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/forgetpswd">
              <Forgetpswd />
            </Route>
            <Route exact path="/resetpassword">
              <Resetpassword />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/cars">
              <Cars />
            </Route>
            <Route exact path="/interior">
              <Interior />
            </Route>
            <Route exact path="/uploaded-images">
              <Uploadedimages />
            </Route>
            <Route exact path="/seller-docs">
              <Sellerdocs />
            </Route>
            <Route exact path="/insreport">
              <Insreport />
            </Route>
            <Route exact path="/carsinfo">
              <Carsinfo />
            </Route>
            <Route exact path="/basicinfo">
              <Basicinfo />
            </Route>
            <Route exact path="/documentation">
              <Doc />
            </Route>
            <Route exact path="/exterior">
              <Exterior />
            </Route>
            <Route exact path="/tyres">
              <Tyres />
            </Route>
            <Route exact path="/windshield">
              <Windshield />
            </Route>
            <Route exact path="/engine">
              <Engine />
            </Route>
            <Route exact path="/ac">
              <Ac />
            </Route>
            <Route exact path="/electrical">
              <Electrical />
            </Route>
            <Route exact path="/steering">
              <Steering />
            </Route>
            <Route exact path="/carprofile">
              <Carprofile />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route exact path="/notifications">
              <Notifications />
            </Route>
            <Route exact path="/termsandcondition">
              <Termsandcondition />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </InspectionImageContextComponent>
  )
};


export default App;
