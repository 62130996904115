import './Doc.css';
import { IonPage, IonCard, IonCardHeader, IonLoading, IonCardTitle, IonSelect, IonContent, useIonViewDidEnter, useIonToast } from '@ionic/react';
import { IonTextarea, IonSelectOption, IonInput } from '@ionic/react';
import Header from '../../components/header/Header';
import Navmenu from '../../components/navmenu/Navmenu';
import IonIcon from '@reacticons/ionicons';
import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { getQueryParams } from '../../services/helper.service';
import { updatedocuments, carinfo, listRto } from '../../services/cars.service';
import { getData } from '../../services/localstorage.service';
import { imagelistingbyscreen } from '../../services/camera.service';
import { takePicture, uploadimage } from '../../services/camera.service';
import Select from "react-select";

function Doc() {
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);
    const [presentToast] = useIonToast();
    const [rtoCars, setRtoCars] = useState<any[]>([]);

    const presentToastr = (message: string, color: string) => {
        presentToast({
            message: message,
            duration: 3000,
            position: 'top',
            color: color
        });
    }

    const [documents, setDocuments] = useState<any>({
        id: "",
        car_id: "",
        lead_id: getQueryParams('id'),
        inspector_id: "",
        rc_availability: "",
        rc_condition: "",
        mismatch_rc: "",
        rto_noc_issued: "",
        no_claim_bonus: "",
        under_hypothecation: "",
        loan_status: "",
        insurance_type: "",
        insurance_expiry: "",
        road_tax: "",
        partypeshi_rq: "",
        duplicate_key: "",
        chasis_no_embossing: "",
        mfg_date: "",
        date_of_reg: "",
        rto: "",
        fitness_upto: "",
        cng_lpg_fitment: "",
        cng_fitment: "",
        comment: "",
        owner_type: "",
        documentation_completed: "1"
    });

    let [imagesObj, setImagesObj] = useState<any>({
        'rc_availability_image': null,
        'insurance_type_image': null,
        'chasis_no_embossing_image': null
    });

    let listImages = () => {
        imagelistingbyscreen({
            'screen_type': 'documentation',
            "car_id": getQueryParams('cid'),
            'lead_id': getQueryParams('id')
        }).then(images => {
            if (images.data.length > 0) {
                let imageMap: any = {};
                images.data.map((image: any) => {
                    imageMap[image.image_type] = image.path;
                })
                setImagesObj({
                    ...imagesObj,
                    ...imageMap
                });
            }
        });
    }

    const handleImageUpload = async (imageType: string, edit: boolean = false) => {
        let imgData = await takePicture(edit);
        if (imgData) {
            setShowLoading(true);
            await uploadimage({
                "car_id": getQueryParams('cid'),
                "lead_id": getQueryParams('id'),
                [imageType]: imgData
            });
            setShowLoading(false);
        }
        listImages();
    };

    const handleInputs = async (e: any) => {
        let inputObj = {
            "name": e.target.name,
            "value": e.target.value
        };
        setDocuments({
            ...documents,
            [inputObj.name]: inputObj.value
        });
    }

    const getRtoList = () => {
        setShowLoading(true);
        listRto().then(rtoData => {
            setShowLoading(false);
            if (rtoData.success) {
                if (rtoData.data.data.length > 0) {
                    setRtoCars(rtoData.data.data);
                }
            }
        }).catch((err)=>{
            setShowLoading(false);
        });
    }

    const submitDocsInfo = async () => {
        setShowLoading(true);
        let updatedocs = await updatedocuments(documents);
        setShowLoading(false);
        if (updatedocs.success) {
            presentToastr(updatedocs.message, 'success');
            history.replace('/carsinfo?id=' + getQueryParams('id'));
        } else {
            presentToastr(updatedocs.message, 'danger');
        }
    }

    const getCarinfo = async () => {
        let carData: any = await carinfo(getQueryParams('id'));
        if (carData.success) {
            
            if (carData.data.data.length == 0) {
                presentToastr('Car not found', 'danger');
                history.goBack();
            }
            let cardocument = carData.data.data[0].cars.documentation;
            let inspectorData = JSON.parse(getData('userprofile_details'));
            if (cardocument != null) {
                setDocuments({
                    ...documents,
                    car_id: carData.data.data[0].car_id,
                    inspector_id: inspectorData.id,
                    id: cardocument.id,
                    rc_availability: cardocument.rc_availability,
                    rc_condition: cardocument.rc_condition,
                    mismatch_rc: cardocument.mismatch_rc,
                    rto_noc_issued: cardocument.rto_noc_issued,
                    no_claim_bonus: cardocument.no_claim_bonus,
                    under_hypothecation: cardocument.under_hypothecation,
                    loan_status: cardocument.loan_status,
                    insurance_type: cardocument.insurance_type,
                    road_tax: cardocument.road_tax,
                    partypeshi_rq: cardocument.partypeshi_rq,
                    duplicate_key: cardocument.duplicate_key,
                    chasis_no_embossing: cardocument.chasis_no_embossing,
                    mfg_date: carData.data.data[0].cars.make_year,
                    date_of_reg: carData.data.data[0].cars.registration_date,
                    rto: cardocument.rto,
                    fitness_upto: carData.data.data[0].cars.expiration_date,
                    cng_lpg_fitment: cardocument.cng_lpg_fitment,
                    cng_fitment: cardocument.cng_fitment,
                    owner_type: cardocument.owner_type,
                    insurance_expiry: cardocument.insurance_expiry,
                    comment: cardocument.comment
                });
            // setSelectedOptions([{"value": cardocument.rto, "label": cardocument.rto}]);
            } else {
                setDocuments({
                    ...documents,
                    car_id: carData.data.data[0].car_id,
                    inspector_id: inspectorData.id
                });
            }
        }
    }

    useIonViewDidEnter(async () => {
        setShowLoading(true);
        await getCarinfo();
        setShowLoading(false);
        listImages();
        getRtoList();
    });

    let myUpdatedVehicle;

  // Function triggered on selection
  function handleSelect(data: any) {
      setDocuments({
              ...documents,
              rto: data.value
          })
  }

    return (
        <>
            <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header pageTitle="Documentation" />
                <IonContent className="ion-padding" placeholder={undefined}>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                    />
                    <h2>RC Availability</h2>
                    <div className='docs-rc'>
                        <IonCard className="card-modalsrch" placeholder={undefined}>
                            <div className='carsmfg-year docsrcheader'>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>RC Availability<IonIcon className={(imagesObj.rc_availability_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('rc_availability_image') }} name="camera"></IonIcon></IonCardTitle></IonCardHeader>
                                    <IonSelect name="rc_availability" placeholder="RC Availability" value={documents.rc_availability} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                        <IonSelectOption value="yes" placeholder={undefined}>Yes</IonSelectOption>
                                        <IonSelectOption value="no" placeholder={undefined}>No</IonSelectOption>
                                    </IonSelect>
                                </div>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>RC Condition</IonCardTitle></IonCardHeader>
                                    <IonSelect name="rc_condition" placeholder="RC Condition" value={documents.rc_condition} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                        <IonSelectOption value="Original" placeholder={undefined}>Original</IonSelectOption>
                                        <IonSelectOption value="Photocopy" placeholder={undefined}>Photocopy</IonSelectOption>
                                        <IonSelectOption value="Duplicate" placeholder={undefined}>Duplicate</IonSelectOption>
                                        <IonSelectOption value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                        <IonSelectOption value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    </IonSelect>
                                </div>
                            </div>
                        </IonCard>
                    </div>
                    <div className='docs-rc'>
                        <IonCard className="card-modalsrch" placeholder={undefined}>
                            <div className='carsmfg-year docsrcheader'>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Mismatch In RC</IonCardTitle></IonCardHeader>
                                    <IonSelect name="mismatch_rc" placeholder="RC Mismatch" value={documents.mismatch_rc} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                        <IonSelectOption value="yes" placeholder={undefined}>Yes</IonSelectOption>
                                        <IonSelectOption value="no" placeholder={undefined}>No</IonSelectOption>
                                    </IonSelect>
                                </div>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Owner Type</IonCardTitle></IonCardHeader>
                                    <IonSelect name="owner_type" placeholder="Select Owner Type" value={documents.owner_type} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                        <IonSelectOption value="Individual" placeholder={undefined}>Individual</IonSelectOption>
                                        <IonSelectOption value="Company" placeholder={undefined}>Company</IonSelectOption>
                                    </IonSelect>
                                </div>
                            </div>
                        </IonCard>
                    </div>

                    <div className='doc-cards'>
                        <IonCard className="card-modalsrch" placeholder={undefined}>
                            <div className='carsmfg-year docsrcheader'>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>RTO NOC Issued</IonCardTitle></IonCardHeader>
                                <IonSelect name="rto_noc_issued" placeholder="RTO NOC" value={documents.rto_noc_issued} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption value="yes" placeholder={undefined}>Yes</IonSelectOption>
                                    <IonSelectOption value="no" placeholder={undefined}>No</IonSelectOption>
                                </IonSelect>
                            </div>
                        </IonCard>
                    </div>

                    <h2>Insurance</h2>
                    <div className='docs-rc'>
                        <IonCard className="card-modalsrch" placeholder={undefined}>
                            <div className='carsmfg-year docsrcheader'>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Insurance Type<IonIcon className={(imagesObj.insurance_type_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('insurance_type_image') }} name="camera"></IonIcon></IonCardTitle></IonCardHeader>
                                    <IonSelect name="insurance_type" placeholder="Comprehensive" value={documents.insurance_type} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                        <IonSelectOption value="Comprehensive" placeholder={undefined}>Comprehensive</IonSelectOption>
                                        <IonSelectOption value="Third Party" placeholder={undefined}>Third Party</IonSelectOption>
                                        <IonSelectOption value="Zero Dep" placeholder={undefined}>Zero Dep</IonSelectOption>
                                        <IonSelectOption value="Expired" placeholder={undefined}>Expired</IonSelectOption>
                                        <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                    </IonSelect>
                                </div>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Insurance Expiry</IonCardTitle></IonCardHeader>
                                    <IonInput clearOnEdit={true} name="insurance_expiry" type="date" placeholder="yyyy" value={documents.insurance_expiry} onIonChange={(event: any) => { handleInputs(event) }}></IonInput>
                                </div>
                            </div>
                        </IonCard>
                    </div>

                    <div className='doc-cards'>
                        <IonCard className="card-modalsrch" placeholder={undefined}>
                            <div className='carsmfg-year docsrcheader'>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>No claim Bonus</IonCardTitle></IonCardHeader>
                                    <IonSelect name="no_claim_bonus" placeholder="No claim Bonus" value={documents.no_claim_bonus} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                        <IonSelectOption value="yes" placeholder={undefined}>Yes</IonSelectOption>
                                        <IonSelectOption value="no" placeholder={undefined}>No</IonSelectOption>
                                    </IonSelect>
                                </div>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Under Hypothecation</IonCardTitle></IonCardHeader>
                                    <IonSelect name="under_hypothecation" placeholder="Under Hypothecation" value={documents.under_hypothecation} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                        <IonSelectOption value="yes" placeholder={undefined}>Yes</IonSelectOption>
                                        <IonSelectOption value="no" placeholder={undefined}>No</IonSelectOption>
                                    </IonSelect>
                                </div>
                            </div>
                        </IonCard>
                    </div>
                    <div className='doc-cards'>
                        <IonCard className="card-modalsrch" placeholder={undefined}>
                            <div className='carsmfg-year docsrcheader'>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>If Yes Then Loan Status</IonCardTitle></IonCardHeader>
                                    <IonSelect name="loan_status" placeholder="If Yes Then Loan Status" value={documents.loan_status} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                        <IonSelectOption value="Open" placeholder={undefined}>Open</IonSelectOption>
                                        <IonSelectOption value="Close" placeholder={undefined}>Close</IonSelectOption>
                                    </IonSelect>
                                </div>
                            </div>
                        </IonCard>
                    </div>

                    <h2>Other Information</h2>
                    <div className='doc-cards'>
                        <IonCard className="card-modalsrch" placeholder={undefined}>
                            <div className='carsmfg-year docsrcheader'>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Road Tax Paid</IonCardTitle></IonCardHeader>
                                    <IonSelect name="road_tax" placeholder="Road Tax Paid" value={documents.road_tax} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                        <IonSelectOption value="One Time Paid" placeholder={undefined}>One Time Paid</IonSelectOption>
                                        <IonSelectOption value="No" placeholder={undefined}>No</IonSelectOption>
                                    </IonSelect>
                                </div>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Partypeshi Request</IonCardTitle></IonCardHeader>
                                    <IonSelect name="partypeshi_rq" placeholder="Part Req." value={documents.partypeshi_rq} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                        <IonSelectOption value="yes" placeholder={undefined}>Yes</IonSelectOption>
                                        <IonSelectOption value="no" placeholder={undefined}>No</IonSelectOption>
                                    </IonSelect>
                                </div>
                            </div>
                        </IonCard>
                    </div>
                    <div className='doc-cards'>
                        <IonCard className="card-modalsrch" placeholder={undefined}>
                            <div className='carsmfg-year docsrcheader'>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Duplicate Key</IonCardTitle></IonCardHeader>
                                    <IonSelect name="duplicate_key" placeholder="Duplicate Key" value={documents.duplicate_key} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                        <IonSelectOption value="yes" placeholder={undefined}>Yes</IonSelectOption>
                                        <IonSelectOption value="no" placeholder={undefined}>No</IonSelectOption>
                                    </IonSelect>
                                </div>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Chasis No. Embossing<IonIcon className={(imagesObj.chasis_no_embossing_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('chasis_no_embossing_image') }} name="camera"></IonIcon></IonCardTitle></IonCardHeader>
                                    <IonSelect name="chasis_no_embossing" placeholder="Chasis No." value={documents.chasis_no_embossing} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                        <IonSelectOption value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                        <IonSelectOption value="Not Visible Properly" placeholder={undefined}>Not Visible Properly</IonSelectOption>
                                    </IonSelect>
                                </div>
                            </div>
                        </IonCard>
                    </div>
                    <h2>Registration & Fitness</h2>
                    <div>
                        <IonCard className="card-modalsrch d-none" placeholder={undefined}>
                            <div className='carsmfg-year'>
                                <div>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Mfg Date</IonCardTitle></IonCardHeader>
                                    <IonInput clearOnEdit={true} name="mfg_date" type="date" placeholder="yyyy" value={documents.mfg_date} onIonChange={(event: any) => { handleInputs(event) }}></IonInput>
                                </div>
                                <div className='reg-date'>
                                    <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Date of Reg..</IonCardTitle></IonCardHeader>
                                    <IonInput clearOnEdit={true} name="date_of_reg" type="date" placeholder="yyyy" value={documents.date_of_reg} onIonChange={(event: any) => { handleInputs(event) }}></IonInput>
                                </div>
                            </div>
                        </IonCard>
                        <div className='doc-cards-select'>
                        <h4 style={{marginLeft: 10, fontWeight: 'bold', color: '#262626'}}>RTO</h4>
                            <div className="dropdown-container">
                                <Select
                                options={rtoCars.map(rto => {
                                    if(rto.id == documents.rto){
                                        myUpdatedVehicle = { "value" : rto.id, "label": rto.rto_code }
                                    }
                                    return { "value" : rto.id, "label": rto.rto_code }
                                })}
                                placeholder="Select RTO"
                                value={myUpdatedVehicle}
                                onChange={handleSelect}
                                isSearchable={true}
                                />
                            </div>
                        </div>
                        <div className='doc-cards'>
                            <IonCard className="card-modalsrch" placeholder={undefined}>
                                <div className='carsmfg-year docsrcheader'>
                                    <div>
                                        <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>CNG/LPG Fitment in RC</IonCardTitle></IonCardHeader>
                                        <IonSelect name="cng_lpg_fitment" placeholder="CNG/LPG Fitment" value={documents.cng_lpg_fitment} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                            <IonSelectOption value="Yes" placeholder={undefined}>Yes</IonSelectOption>
                                            <IonSelectOption value="No" placeholder={undefined}>No</IonSelectOption>
                                            <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                        </IonSelect>
                                    </div>
                                </div>
                            </IonCard>
                        </div>
                        <div className='doc-cards'>
                            <IonCard className="card-modalsrch" placeholder={undefined}>
                                <div className='carsmfg-year docsrcheader'>
                                    <div>
                                        <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>CNG Fitment in Vehicle</IonCardTitle></IonCardHeader>
                                        <IonSelect name="cng_fitment" placeholder="CNG Fitment in Vehicle" value={documents.cng_fitment} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                            <IonSelectOption value="Yes" placeholder={undefined}>Yes</IonSelectOption>
                                            <IonSelectOption value="No" placeholder={undefined}>No</IonSelectOption>
                                            <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                        </IonSelect>
                                    </div>
                                </div>
                            </IonCard>
                        </div>
                    </div>
                    <div className="modal-txtarea">
                        <IonTextarea name="comment" value={documents.comment} placeholder="Enter Remark" onIonChange={(event: any) => { handleInputs(event) }}></IonTextarea>
                    </div>
                    <IonButton onClick={async () => { await submitDocsInfo(); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
                </IonContent>
            </IonPage>
        </>
    )
}

export default Doc