import { ENV } from '../env';
import { getData } from './localstorage.service';

// user profile Api Integration
export const userprofile = async (): Promise<any> =>{
    let response = await fetch(ENV.endpoint + "user-profile", {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer '+ getData('token')
        },
    });
    return response.json();
}

//users profile update
export const profileupdate = async (profileDetails: any): Promise<any> =>{
    let response = await fetch(ENV.endpoint + "profile-update", {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer '+ getData('token')
        },
        body: JSON.stringify(profileDetails)
    });
    return response.json();
}