import './Ac.css';
import { 
    IonCard, 
    IonPage, 
    IonContent, 
    IonCardHeader, 
    IonCardTitle, 
    IonSelectOption, 
    IonSelect, 
    IonTextarea, 
    IonButton,
    useIonToast,
    IonLoading,
    useIonViewDidEnter
    } from '@ionic/react';


import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import { useHistory } from 'react-router';
import Staricons from '../../components/staricons/Staricons';
import { getQueryParams } from '../../services/helper.service';
import { AcHeater, carinfo } from '../../services/cars.service';
import { useState } from 'react';
import { getData } from '../../services/localstorage.service';

function Ac() {
    const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
    const [presentToast] = useIonToast();

    const presentToastr = (message: string, color: string) => {
        presentToast({
          message: message,
          duration: 3000,
          position: 'top',
          color: color
        });
      }
      
      const[AC, setAC] = useState<any>({
        'id'                            :'',
        'car_id'                        :'',
        'lead_id'                       :getQueryParams('id'),
        'inspector_id'                  :'',
        'ac_type'                       :'',
        'compressor'                    :'',
        'condenser'                     :'',
        'ac_cooling'                    :'',
        'heater'                        :'',
        'comment'                       :'',
        'rating'                        :'0',
        'ac_and_heater_completed'       :'1',
    })

    const handleInputs = async (e: any)=>{
        let inputObj = {
            "name": e.target.name,
            "value": e.target.value
        };
        setAC({
            ...AC,
            [inputObj.name]: inputObj.value
        });
    }

    const getCarinfo =async()=>{
       let carData = await carinfo(getQueryParams('id')); 
            if(carData.success){
                if(carData.data.data.length == 0){
                    presentToastr('car not found', 'danger');
                    history.goBack();
                };
                let carAC = carData.data.data[0].cars.ac_and_heater
                let inspectorData = JSON.parse(getData('userprofile_details'));
                setAC({
                    ...AC,
                    car_id: carData.data.data[0].cars.id,
                    inspector_id: inspectorData.id
                })
                if(carAC != null){
                    setAC({
                        ...AC,
                        'id'                            :carAC.id,
                        'car_id'                        :carData.data.data[0].cars.id,
                        'lead_id'                       :getQueryParams('id'),
                        'inspector_id'                  :inspectorData.id,
                        'ac_type'                       :carAC.ac_type,
                        'compressor'                    :carAC.compressor,
                        'condenser'                     :carAC.condenser,
                        'ac_cooling'                    :carAC.ac_cooling,
                        'heater'                        :carAC.heater,
                        'comment'                       :carAC.comment,
                        'rating'                        :carAC.rating,
                    })
                }
            }       
    }

    useIonViewDidEnter(async ()=>{
        setShowLoading(true);
        await getCarinfo();
        setShowLoading(false);
      });

    const submitAcHeater = async ()=>{
        setShowLoading(true);
        let AcHeaterinfo =  await AcHeater(AC);
        setShowLoading(false);
        if(AcHeaterinfo.success){
        presentToastr(AcHeaterinfo.message, 'success');
        history.replace('/carsinfo?id='+ getQueryParams('id'));
       }else{
        presentToastr(AcHeaterinfo.message, 'danger');
        }
    }

    const updateRating = (rating: any) => {
        setAC({
            ...AC,
            'rating': rating
        });
    }
    return (
        <div>
            <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header pageTitle="Ac and Heater" />
                <IonContent className="ion-padding" placeholder={undefined}>
                <IonLoading
                            cssClass='my-custom-class'
                            isOpen={showLoading}
                            onDidDismiss={() => setShowLoading(false)}
                            message={'Loading...'}
                        />
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>AC Type</IonCardTitle></IonCardHeader>
                                <IonSelect placeholder="ok" name="ac_type" value={AC.ac_type} onIonDismiss={(event: any)=>{handleInputs(event)}}>
                                    <IonSelectOption value="Climate Control" placeholder={undefined}>Climate Control</IonSelectOption>
                                    <IonSelectOption value="Manual AC" placeholder={undefined}>Manual AC</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Compressor</IonCardTitle></IonCardHeader>
                                <IonSelect placeholder="ok" name="compressor" value={AC.compressor} onIonDismiss={(event: any)=>{handleInputs(event)}}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Minor Sound" placeholder={undefined}>Minor Sound</IonSelectOption>
                                </IonSelect>
                            </div>
                        </div>
                    </IonCard>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Condenser</IonCardTitle></IonCardHeader>
                                <IonSelect placeholder="ok" name="condenser" value={AC.condenser} onIonDismiss={(event: any)=>{handleInputs(event)}}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                </IonSelect>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>AC Cooling</IonCardTitle></IonCardHeader>
                                <IonSelect placeholder="ok" name="ac_cooling" value={AC.ac_cooling} onIonDismiss={(event: any)=>{handleInputs(event)}}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                </IonSelect>
                            </div>
                        </div>
                    </IonCard>
                    <IonCard className="card-modalsrch" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Heater</IonCardTitle></IonCardHeader>
                                <IonSelect placeholder="ok" name="heater" value={AC.heater} onIonDismiss={(event: any)=>{handleInputs(event)}}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                </IonSelect>
                            </div>
                        </div>
                    </IonCard>
                    </IonContent>
                    <div className="ac-txt">
                    <div className="modal-txtarea">
                        <IonTextarea name="comment" value={AC.comment} placeholder="Enter Remark" onIonChange={(event: any)=>{handleInputs(event)}}></IonTextarea>
                    </div>
                    <Staricons rating={parseInt(AC.rating)} handleRating={updateRating}/>
                    <IonButton onClick={async () => { await submitAcHeater(); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
                    </div>
            </IonPage>
        </div>
    )
}

export default Ac
