import {createContext, useState} from "react";

type ImageContextProps = {
    children: React.ReactNode;
};

let initiaState: any = {};

export const InspectionImageContext = createContext(initiaState);

export const InspectionImageContextComponent = (props: ImageContextProps) => {
    let [inspectionImages, setInspectionImages] = useState([]);
    return (<InspectionImageContext.Provider value={{
        inspectionImages,
        setInspectionImages
    }}>
        {props.children}
    </InspectionImageContext.Provider>);
}