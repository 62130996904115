import './Tyres.css';
import { IonPage, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonSelect, IonSelectOption, useIonViewDidEnter, useIonToast, IonLoading, IonItem, IonLabel, IonTextarea, IonButton } from '@ionic/react';
import { getQueryParams, parsejsonstring } from '../../services/helper.service';

import Header from '../../components/header/Header';
import Navmenu from '../../components/navmenu/Navmenu';
import IonIcon from '@reacticons/ionicons';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { updatetyres, carinfo } from '../../services/cars.service';
import { getData } from '../../services/localstorage.service';
import Staricons from '../../components/staricons/Staricons';
import { imagelistingbyscreen } from '../../services/camera.service';
import { takePicture, uploadimage } from '../../services/camera.service';

function Tyres() {
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);
    const [presentToast] = useIonToast();

    const presentToastr = (message: string, color: string) => {
        presentToast({
            message: message,
            duration: 3000,
            position: 'top',
            color: color
        });
    }

    const [tyres, setTyres] = useState<any>({
        'id': '',
        'car_id': '',
        'lead_id': getQueryParams('id'),
        'inspector_id': '',
        'front_rhs_tyre': '',
        'front_rhs_tyre_type': '',
        'front_rhs_tyre_condition': '',
        'front_lhs_tyre': '',
        'front_lhs_tyre_type': '',
        'front_lhs_tyre_condition': '',
        'back_rhs_tyre': '',
        'back_rhs_tyre_type': '',
        'back_rhs_tyre_condition': '',
        'back_lhs_tyre': '',
        'back_lhs_tyre_type': '',
        'back_lhs_tyre_condition': '',
        'spare_tyre': '',
        'spare_tyre_type': '',
        'spare_tyre_condition': '',
        'tyre_type': '',
        'wheel_type': '',
        'wheel_condition': '',
        'rating': 0,
        'comment': '',
        'tyre_toolkit': '',
        'exterior_tyre_completed': '1',
    })

    let [imagesObj, setImagesObj] = useState<any>({});

    let listImages = () => {
        imagelistingbyscreen({
            'screen_type': 'exterior-tyre',
            "car_id": getQueryParams('cid'),
            'lead_id': getQueryParams('id')
        }).then(images => {
            if (images.data.length > 0) {
                let imageMap: any = {};
                images.data.map((image: any) => {
                    imageMap[image.image_type] = image.path;
                })
                setImagesObj({
                    ...imagesObj,
                    ...imageMap
                });
            }
        });
    }

    const handleImageUpload = async (imageType: string, edit: boolean = false) => {
        let imgData = await takePicture(edit);
        if (imgData) {
            setShowLoading(true);
            await uploadimage({
                "car_id": getQueryParams('cid'),
                "lead_id": getQueryParams('id'),
                [imageType]: imgData
            });
            setShowLoading(false);
        }
        listImages();
    };


    const handleInputs = async (e: any) => {
        let inputObj = {
            "name": e.target.name,
            "value": e.target.value
        };
        setTyres({
            ...tyres,
            [inputObj.name]: inputObj.value
        });
    }

    const submitTyresInfo = async () => {
        setShowLoading(true);
        let tyresinfo = await updatetyres(tyres);
        setShowLoading(false);
        if (tyresinfo.success) {
            presentToastr(tyresinfo.message, 'success');
            history.replace('/carsinfo?id=' + getQueryParams('id'));
        } else {
            presentToastr(tyresinfo.message, 'danger');
        }
    }
    const getCarinfo = async () => {
        let carData: any = await carinfo(getQueryParams('id'));
        if (carData.success) {
            if (carData.data.data.length == 0) {
                presentToastr('Car not found', 'danger');
                history.goBack();
            }
            let cartyres = carData.data.data[0].cars.exterior_tyre;
            let inspectorData = JSON.parse(getData('userprofile_details'));
            setTyres({
                ...tyres,
                car_id: carData.data.data[0].cars.id,
                inspector_id: inspectorData.id
            });
            if (cartyres != null) {
                setTyres({
                    ...tyres,
                    'id': cartyres.id,
                    'car_id': carData.data.data[0].cars.id,
                    'lead_id': getQueryParams('id'),
                    'inspector_id': inspectorData.id,
                    'front_rhs_tyre': parsejsonstring(cartyres.front_rhs_tyre),
                    'front_rhs_tyre_type': parsejsonstring(cartyres.front_rhs_tyre_type),
                    'front_rhs_tyre_condition': parsejsonstring(cartyres.front_rhs_tyre_condition),
                    'front_lhs_tyre': parsejsonstring(cartyres.front_lhs_tyre),
                    'front_lhs_tyre_type': parsejsonstring(cartyres.front_lhs_tyre_type),
                    'front_lhs_tyre_condition': parsejsonstring(cartyres.front_lhs_tyre_condition),
                    'back_rhs_tyre': parsejsonstring(cartyres.back_rhs_tyre),
                    'back_rhs_tyre_type': parsejsonstring(cartyres.back_rhs_tyre_type),
                    'back_rhs_tyre_condition': parsejsonstring(cartyres.back_rhs_tyre_condition),
                    'back_lhs_tyre': parsejsonstring(cartyres.back_lhs_tyre),
                    'back_lhs_tyre_type': parsejsonstring(cartyres.back_lhs_tyre_type),
                    'back_lhs_tyre_condition': parsejsonstring(cartyres.back_lhs_tyre_condition),
                    'spare_tyre': parsejsonstring(cartyres.spare_tyre),
                    'spare_tyre_type': parsejsonstring(cartyres.spare_tyre_type),
                    'spare_tyre_condition': parsejsonstring(cartyres.spare_tyre_condition),
                    'tyre_type': parsejsonstring(cartyres.tyre_type),
                    'wheel_type': parsejsonstring(cartyres.wheel_type),
                    'wheel_condition': parsejsonstring(cartyres.wheel_condition),
                    'tyre_toolkit': parsejsonstring(cartyres.tyre_toolkit),
                    'rating': cartyres.rating,
                    'comment': cartyres.comment,
                });
            }
        }
    }

    const updateRating = (rating: any) => {
        setTyres({
            ...tyres,
            'rating': rating
        });
    }

    useIonViewDidEnter(async () => {
        setShowLoading(true);
        await getCarinfo();
        setShowLoading(false);
        listImages();
    });


    return (
        <div>
            <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header pageTitle="Exterior / Tyre" />
                <IonContent className="ion-padding" placeholder={undefined}>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                    />
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div className="tyres">
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Front RHS Tyre</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.front_rhs_tyre_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('front_rhs_tyre_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <h6>Tyre</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="front_rhs_tyre" value={tyres.front_rhs_tyre} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-danger" value="0-25%" placeholder={undefined}>0-25 %</IonSelectOption>
                                    <IonSelectOption className="color-success" value="25-60%" placeholder={undefined}>25-60 %</IonSelectOption>
                                    <IonSelectOption className="color-success" value="61-100 %" placeholder={undefined}>61-100 %</IonSelectOption>
                                </IonSelect>
                                <h6>Tyre Type</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="front_rhs_tyre_type" value={tyres.front_rhs_tyre_type} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ream" placeholder={undefined}>Ream</IonSelectOption>
                                    <IonSelectOption className="color-success" value="Alloy" placeholder={undefined}>Alloy</IonSelectOption>
                                </IonSelect>
                                <h6>Alloy/Rim Condition</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="front_rhs_tyre_condition" value={tyres.front_rhs_tyre_condition} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratch" placeholder={undefined}>Scratch</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div className="tyres">
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Rear RHS Tyre</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.back_rhs_tyre_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('back_rhs_tyre_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <h6>Tyre</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="back_rhs_tyre" value={tyres.back_rhs_tyre} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-danger" value="0-25%" placeholder={undefined}>0-25 %</IonSelectOption>
                                    <IonSelectOption className="color-success" value="25-60%" placeholder={undefined}>25-60 %</IonSelectOption>
                                    <IonSelectOption className="color-success" value="61-100 %" placeholder={undefined}>61-100 %</IonSelectOption>
                                </IonSelect>
                                <h6>Tyre Type</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="back_rhs_tyre_type" value={tyres.back_rhs_tyre_type} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ream" placeholder={undefined}>Ream</IonSelectOption>
                                    <IonSelectOption className="color-success" value="Alloy" placeholder={undefined}>Alloy</IonSelectOption>
                                </IonSelect>
                                <h6>Alloy/Rim Condition</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="back_rhs_tyre_condition" value={tyres.back_rhs_tyre_condition} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratch" placeholder={undefined}>Scratch</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div className="tyres">
                            <IonCardHeader className='exterior-cardheader tyres' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Spare Tyre</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.spare_tyre_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('spare_tyre_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <h6>Tyre</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="spare_tyre" value={tyres.spare_tyre} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-danger" value="0-25%" placeholder={undefined}>0-25 %</IonSelectOption>
                                    <IonSelectOption className="color-success" value="25-60%" placeholder={undefined}>25-60 %</IonSelectOption>
                                    <IonSelectOption className="color-success" value="61-100 %" placeholder={undefined}>61-100 %</IonSelectOption>
                                </IonSelect>
                                <h6>Tyre Type</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="spare_tyre_type" value={tyres.spare_tyre_type} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ream" placeholder={undefined}>Ream</IonSelectOption>
                                    <IonSelectOption className="color-success" value="Alloy" placeholder={undefined}>Alloy</IonSelectOption>
                                </IonSelect>
                                <h6>Alloy/Rim Condition</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="spare_tyre_condition" value={tyres.spare_tyre_condition} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratch" placeholder={undefined}>Scratch</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div className="tyres">
                            <IonCardHeader className='exterior-cardheader tyres' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Front LHS Tyre</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.front_lhs_tyre_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('front_lhs_tyre_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <h6>Tyre</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="front_lhs_tyre" value={tyres.front_lhs_tyre} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-danger" value="0-25%" placeholder={undefined}>0-25 %</IonSelectOption>
                                    <IonSelectOption className="color-success" value="25-60%" placeholder={undefined}>25-60 %</IonSelectOption>
                                    <IonSelectOption className="color-success" value="61-100 %" placeholder={undefined}>61-100 %</IonSelectOption>
                                </IonSelect>
                                <h6>Tyre Type</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="front_lhs_tyre_type" value={tyres.front_lhs_tyre_type} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ream" placeholder={undefined}>Ream</IonSelectOption>
                                    <IonSelectOption className="color-success" value="Alloy" placeholder={undefined}>Alloy</IonSelectOption>
                                </IonSelect>
                                <h6>Alloy/Rim Condition</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="front_lhs_tyre_condition" value={tyres.front_lhs_tyre_condition} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratch" placeholder={undefined}>Scratch</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div className="tyres">
                            <IonCardHeader className='exterior-cardheader tyres' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Rear LHS Tyre</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.back_lhs_tyre_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('back_lhs_tyre_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <h6>Tyre</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="back_lhs_tyre" value={tyres.back_lhs_tyre} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-danger" value="0-25%" placeholder={undefined}>0-25 %</IonSelectOption>
                                    <IonSelectOption className="color-success" value="25-60%" placeholder={undefined}>25-60 %</IonSelectOption>
                                    <IonSelectOption className="color-success" value="61-100 %" placeholder={undefined}>61-100 %</IonSelectOption>
                                </IonSelect>
                                <h6>Tyre Type</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="back_lhs_tyre_type" value={tyres.back_lhs_tyre_type} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ream" placeholder={undefined}>Ream</IonSelectOption>
                                    <IonSelectOption className="color-success" value="Alloy" placeholder={undefined}>Alloy</IonSelectOption>
                                </IonSelect>
                                <h6>Alloy/Rim Condition</h6>
                                <IonSelect className='reduced-select-width' multiple placeholder="select option" name="back_lhs_tyre_condition" value={tyres.back_lhs_tyre_condition} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratch" placeholder={undefined}>Scratch</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div className="tyres">
                            <IonCardHeader className='exterior-cardheader tyres' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Tyre Toolkit</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.tyre_toolkit_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('tyre_toolkit_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' placeholder="select option" multiple name="tyre_toolkit" value={tyres.tyre_toolkit} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-danger" value="Yes" placeholder={undefined}>Yes</IonSelectOption>
                                    <IonSelectOption className="color-success" value="No" placeholder={undefined}>No</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <div className="modal-txtarea">
                        <IonTextarea name="comment" value={tyres.comment} placeholder="Enter Remark" onIonChange={(event: any) => { handleInputs(event) }}></IonTextarea>
                    </div>
                    <Staricons rating={parseInt(tyres.rating)} handleRating={updateRating} />
                    <IonButton onClick={async () => { await submitTyresInfo(); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
                </IonContent>
            </IonPage>
        </div>
    )
}

export default Tyres