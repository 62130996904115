import './Engine.css';
import {
    IonCard,
    IonCardContent,
    IonPage,
    IonContent,
    IonCardHeader,
    IonCardTitle,
    IonSelectOption,
    IonSelect,
    IonTextarea,
    IonButton,
    useIonToast,
    IonLoading,
    useIonViewDidEnter
} from '@ionic/react';
import { useState } from 'react'
import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import IonIcon from '@reacticons/ionicons';
import { useHistory } from 'react-router';
import { getData } from '../../services/localstorage.service';
import { vehicleengine, carinfo } from '../../services/cars.service';
import Staricons from '../../components/staricons/Staricons';
import { getQueryParams } from '../../services/helper.service';
import { imagelistingbyscreen } from '../../services/camera.service';
import { takePicture, uploadimage } from '../../services/camera.service';
import { MediaCapture, CaptureError, CaptureVideoOptions } from '@awesome-cordova-plugins/media-capture';
import { Filesystem } from '@capacitor/filesystem';

function Engine() {
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);
    const [presentToast] = useIonToast();

    const presentToastr = (message: string, color: string) => {
        presentToast({
            message: message,
            duration: 3000,
            position: 'top',
            color: color
        });
    }

    const captureVideo = async () => {
        let options: CaptureVideoOptions = { limit: 1, duration: 30 };
        MediaCapture.captureVideo(options).then(
            async (data: any) => {
                let localPath = data[0].fullPath;
                let filecontents = await readFilePath(localPath);
                let b64en = 'data:video/mp4;base64,'+filecontents;
                await uploadVideo(b64en);
            },
            (err: CaptureError) => presentToastr('Something went wrong, please try again!', 'error')
        );
    }

    const readFilePath = async (path: any) => {
        const contents = await Filesystem.readFile({
            path: path
        });
        return contents.data;
    };

    const uploadVideo = async (video: any) => {
        setShowLoading(true);
        await uploadimage({
            "car_id": getQueryParams('cid'),
            "lead_id": getQueryParams('id'),
            "sound_video_image": video
        });
        setShowLoading(false);
        listImages();
    }

    const [engines, setEngines] = useState<any>({
        'car_id': '',
        'lead_id': getQueryParams('id'),
        'inspector_id': '',
        'sound': '',
        'smoke': '',
        'permissible': '',
        'clutch': '',
        'mounting': '',
        'oil_level': '',
        'coolant': '',
        'gear_shifting': '',
        'battery': '',
        'head_gas_kit': '',
        'comment': '',
        'rating': '0',
        'engine_completed': '1',
    });

    let [imagesObj, setImagesObj] = useState<any>({});

    let listImages = () => {
        imagelistingbyscreen({
            'screen_type': 'engine',
            "car_id": getQueryParams('cid'),
            'lead_id': getQueryParams('id')
        }).then(images => {
            if (images.data.length > 0) {
                let imageMap: any = {};
                images.data.map((image: any) => {
                    imageMap[image.image_type] = image.path;
                })
                setImagesObj({
                    ...imagesObj,
                    ...imageMap
                });
            }
        });
    }

    const handleImageUpload = async (imageType: string, edit: boolean = false) => {
        let imgData = await takePicture(edit);
        if (imgData) {
            setShowLoading(true);
            await uploadimage({
                "car_id": getQueryParams('cid'),
                "lead_id": getQueryParams('id'),
                [imageType]: imgData
            });
            setShowLoading(false);
        }
        listImages();
    };

    const handleInputs = async (e: any) => {
        let inputObj = {
            "name": e.target.name,
            "value": e.target.value,
        };
        setEngines({
            ...engines,
            [inputObj.name]: inputObj.value,
        })
    }

    const getCarinfo = async () => {
        let carData: any = await carinfo(getQueryParams('id'));
        if (carData.success) {
            if (carData.data.data.length == 0) {
                presentToastr('Car not found', 'danger');
                history.goBack();
            };
            let carengine = carData.data.data[0].cars.engine;
            let inspectorData = JSON.parse(getData('userprofile_details'));
            setEngines({
                ...engines,
                car_id: carData.data.data[0].cars.id,
                inspector_id: inspectorData.id
            });
            if (carengine != null) {
                setEngines({
                    ...engines,
                    'id': carengine.id,
                    'car_id': carData.data.data[0].cars.id,
                    'inspector_id': inspectorData.id,
                    'sound': carengine.sound,
                    'smoke': carengine.smoke,
                    'permissible': carengine.permissible,
                    'clutch': carengine.clutch,
                    'mounting': carengine.mounting,
                    'oil_level': carengine.oil_level,
                    'coolant': carengine.coolant,
                    'gear_shifting': carengine.gear_shifting,
                    'battery': carengine.battery,
                    'head_gas_kit': carengine.head_gas_kit,
                    'comment': carengine.comment,
                    'rating': carengine.rating,
                });
            }
        }
    }

    const submitEngineInfo = async () => {
        setShowLoading(true);
        let engineinfo = await vehicleengine(engines);
        setShowLoading(false);
        if (engineinfo.success) {
            presentToastr(engineinfo.message, 'success');
            history.replace('/carsinfo?id=' + getQueryParams('id'));
        } else {
            presentToastr(engineinfo.message, 'danger');
        }
    }

    const updateRating = (rating: any) => {
        setEngines({
            ...engines,
            'rating': rating
        });
    }

    useIonViewDidEnter(async () => {
        setShowLoading(true);
        await getCarinfo();
        setShowLoading(false);
        listImages();
    });

    return (
        <div>
            <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header pageTitle="Engine" />
                <IonContent className="ion-padding" placeholder={undefined}>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                    />
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Engine Sound</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.sound_video_image != null) ? "wind-cam color-success" : "wind-cam"} onClick={()=>{captureVideo()}} name="videocam"></IonIcon>
                                <IonIcon className={(imagesObj.sound_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('sound_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="sound" value={engines.sound} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Minor Sound" placeholder={undefined}>Minor Sound</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Major Sound" placeholder={undefined}>Major Sound</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Linkage" placeholder={undefined}>Linkage</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Exhaust Smoke</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.smoke_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('smoke_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="smoke" value={engines.smoke} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="White Smoke" placeholder={undefined}>White Smoke</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Black Smoke" placeholder={undefined}>Black Smoke</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Blue Smoke" placeholder={undefined}>Blue Smoke</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Engine Permissible</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.permissible_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('permissible_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="permissible" value={engines.permissible} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Back Compressor" placeholder={undefined}>Back Compressor</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Engine Clutch</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.clutch_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('clutch_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="clutch" value={engines.clutch} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Hard" placeholder={undefined}>Hard</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Sponge" placeholder={undefined}>Sponge</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Low Pick-up" placeholder={undefined}>Low Pick-up</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Engine Mounting</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.mounting_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('mounting_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="mounting" value={engines.mounting} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Vibration" placeholder={undefined}>Vibration</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Engine Oil Level</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.oil_level_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('oil_level_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="oil_level" value={engines.oil_level} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Low" placeholder={undefined}>Low</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Coolant</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.coolant_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('coolant_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="coolant" value={engines.coolant} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Change To Be Required" placeholder={undefined}>Change To Be Required</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Gear Shifting</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.gear_shifting_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('gear_shifting_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="gear_shifting" value={engines.gear_shifting} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Hard" placeholder={undefined}>Hard</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Gear Stuck" placeholder={undefined}>Gear Stuck</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Battery</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.battery_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('battery_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="battery" value={engines.battery} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Change To Be Required" placeholder={undefined}>Change To Be Required</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Head Gas Kit</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.head_gas_kit_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('head_gas_kit_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="head_gas_kit" value={engines.head_gas_kit} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <div className="modal-txtarea">
                        <IonTextarea name="comment" value={engines.comment} placeholder="Enter Remark" onIonChange={(event: any) => { handleInputs(event) }}></IonTextarea>
                    </div>
                    <Staricons rating={parseInt(engines.rating)} handleRating={updateRating} />
                    <IonButton onClick={async () => { submitEngineInfo(); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
                </IonContent>
            </IonPage>
        </div>
    )
}

export default Engine
