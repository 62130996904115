import './Termsandcondition.css';
import { IonPage, IonContent } from '@ionic/react';
import React from 'react'
import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';

const Termsandcondition = () => {
  return (
    <div>
      <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header pageTitle="Terms & Condition" />
                <IonContent className="ion-padding" placeholder={undefined}>
                  <div className="terms-cond">
                    <h1>Terms & Conditions</h1>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni illum quidem recusandae ducimus quos
                            reprehenderit. Veniam, molestias quos, dolorum consequuntur nisi deserunt omnis id illo sit cum qui.
                            Eaque, dicta.
                        </p>
                  </div>
                </IonContent>
                </IonPage>
    </div>
  )
}

export default Termsandcondition
