import './Notifications.css';

import { IonContent, IonPage, IonList, IonItem, IonLoading, useIonToast } from '@ionic/react';

import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import IonIcon from '@reacticons/ionicons';
import { useEffect, useState } from 'react';
import { updatenotification, deletenotification } from '../../services/cars.service';

const Notifications = () => {
  const [notification, setNotification] = useState<any[]>([])
  const [showLoading, setShowLoading] = useState(false);
  const [presentToast] = useIonToast();

  const presentToastr = (message: string, color: string) => {
    presentToast({
      message: message,
      duration: 3000,
      position: 'top',
      color: color
    });
  }


  const carnotification = () => {
    setShowLoading(true);
    updatenotification('').then((res) => {
      setShowLoading(false);
      if (res.success) {
        setNotification(res.data.notifications.data);
      }
    }).catch((err)=>{
      setShowLoading(false);
    });
  }

  useEffect(() => {
    carnotification();
  }, []);

  const carnotify_del = async (id: any) => {
    let ans = window.confirm("Are you sure?");
    if (ans) {
      setShowLoading(true);
      await deletenotification({ 'id': id });
      carnotification();
      setShowLoading(false);
      presentToastr('Deleted Succesfully', 'success');
    }
  }

  return (
    <div>
      <Navmenu />
      <IonPage id="main-content" placeholder={undefined}>
        <Header pageTitle="Notifications" />
        <IonContent className="ion-padding" placeholder={undefined}>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Loading...'}
            />
          <IonList lines="full" placeholder={undefined}>
            {notification.map((notify) => {
              return (
                <IonItem key={notify.id} placeholder={undefined}>
                  <span className='notification-txt'>{notify.data.content}</span>
                  <IonIcon className='notification-icon' name="trash-outline" onClick={async () => { carnotify_del(notify.id) }}></IonIcon>
                </IonItem>)
            }
            )}
          </IonList>
        </IonContent>
      </IonPage>
    </div>
  )
}

export default Notifications