//Production
export const ENV: any = {
    "endpoint": "https://api.cars360.co/api/",
    "per_page": 10,
    "country_id": 101
}

//Staging
// export const ENV: any = {
//     "endpoint": "https://webdemo.apcompinfotech.com/cars360_api/public/api/",
//     "per_page": 10,
//     "country_id": 101
// }