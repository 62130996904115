import './Windshield.css';
import {
    IonCard,
    IonCardContent,
    IonPage,
    IonContent,
    IonCardHeader,
    IonCardTitle,
    IonSelectOption,
    IonSelect,
    IonTextarea,
    IonButton,
    useIonToast,
    IonLoading,
    useIonViewDidEnter
} from '@ionic/react';
import { useState } from 'react'
import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import IonIcon from '@reacticons/ionicons';
import { useHistory } from 'react-router';
import { updatewindshield, carinfo } from '../../services/cars.service';
import { getQueryParams, parsejsonstring } from '../../services/helper.service';
import Staricons from '../../components/staricons/Staricons';
import { getData } from '../../services/localstorage.service';
import { imagelistingbyscreen } from '../../services/camera.service';
import { takePicture, uploadimage } from '../../services/camera.service';

function Windshield() {
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);
    const [presentToast] = useIonToast();

    const presentToastr = (message: string, color: string) => {
        presentToast({
            message: message,
            duration: 3000,
            position: 'top',
            color: color
        });
    }

    const [windshield, setWindShield] = useState<any>({
        'id': '',
        'car_id': '',
        'lead_id': getQueryParams('id'),
        'inspector_id': '',
        'front_windshield': '',
        'rear_windshield': '',
        'orvm_type': '',
        'front_bumper': '',
        'read_bumper': '',
        'right_headlight': '',
        'left_headlight': '',
        'right_fog_light': '',
        'left_fog_light': '',
        'right_tailight': '',
        'left_tailight': '',
        'rating': 0,
        'comment': '',
        'wind_shield_light_completed': '1',
    })

    let [imagesObj, setImagesObj] = useState<any>({});

    let listImages = () => {
        imagelistingbyscreen({
            'screen_type': 'windshield-light',
            "car_id": getQueryParams('cid'),
            'lead_id': getQueryParams('id')
        }).then(images => {
            if (images.data.length > 0) {
                let imageMap: any = {};
                images.data.map((image: any) => {
                    imageMap[image.image_type] = image.path;
                })
                setImagesObj({
                    ...imagesObj,
                    ...imageMap
                });
            }
        });
    }

    const handleImageUpload = async (imageType: string, edit: boolean = false) => {
        let imgData = await takePicture(edit);
        if (imgData) {
            setShowLoading(true);
            await uploadimage({
                "car_id": getQueryParams('cid'),
                "lead_id": getQueryParams('id'),
                [imageType]: imgData
            });
            setShowLoading(false);
        }
        listImages();
    };

    const handleInputs = async (e: any) => {
        let inputObj = {
            "name": e.target.name,
            "value": e.target.value
        };
        setWindShield({
            ...windshield,
            [inputObj.name]: inputObj.value
        });
    }

    const getCarinfo = async () => {
        let carData: any = await carinfo(getQueryParams('id'));
        if (carData.success) {
            if (carData.data.data.length == 0) {
                presentToastr('Car not found', 'danger');
                history.goBack();
            }
            let carlights = carData.data.data[0].cars.windshield_light;
            let inspectorData = JSON.parse(getData('userprofile_details'));
            setWindShield({
                ...windshield,
                car_id: carData.data.data[0].cars.id,
                inspector_id: inspectorData.id
            });
            if (carlights != null) {
                setWindShield({
                    ...windshield,
                    'id': carlights.id,
                    'car_id': carData.data.data[0].cars.id,
                    'lead_id': getQueryParams('id'),
                    'inspector_id': inspectorData.id,
                    'front_windshield': carlights.front_windshield,
                    'rear_windshield': carlights.rear_windshield,
                    'orvm_type': carlights.orvm_type,
                    'front_bumper': parsejsonstring(carlights.front_bumper),
                    'read_bumper': parsejsonstring(carlights.read_bumper),
                    'right_headlight': carlights.right_headlight,
                    'left_headlight': carlights.left_headlight,
                    'right_fog_light': carlights.right_fog_light,
                    'left_fog_light': carlights.left_fog_light,
                    'right_tailight': carlights.right_tailight,
                    'left_tailight': carlights.left_tailight,
                    'rating': carlights.rating,
                    'comment': carlights.comment,
                });
            }
        }
    }

    const submitWindshieldInfo = async () => {
        setShowLoading(true);
        let windshieldinfo = await updatewindshield(windshield);
        setShowLoading(false);
        if (windshieldinfo.success) {
            presentToastr(windshieldinfo.message, 'success');
            history.replace('/carsinfo?id=' + getQueryParams('id'));
        } else {
            presentToastr(windshieldinfo.message, 'danger');
        }
    }

    const updateRating = (rating: any) => {
        setWindShield({
            ...windshield,
            'rating': rating
        });
    }

    useIonViewDidEnter(async () => {
        setShowLoading(true);
        await getCarinfo();
        setShowLoading(false);
        listImages();
    });

    return (
        <div>
            <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header pageTitle="Windshield & Light" />
                <IonContent className="ion-padding" placeholder={undefined}>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                    />
                    <h2>Windshield and Light</h2>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Front Windshield</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.front_windshield_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('front_windshield_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="front_windshield" value={windshield.front_windshield} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Spot" placeholder={undefined}>Spot</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Rear Windshield</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.rear_windshield_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('rear_windshield_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="rear_windshield" value={windshield.rear_windshield} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Spot" placeholder={undefined}>Spot</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Orvm Type</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.orvm_type_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('orvm_type_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="orvm_type" value={windshield.orvm_type} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption value="Manual" placeholder={undefined}>Manual</IonSelectOption>
                                    <IonSelectOption value="Automatic" placeholder={undefined}>Automatic</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Front Bumper</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.front_bumper_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('front_bumper_image', true) }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="front_bumper" value={windshield.front_bumper} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Rear Bumper</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.read_bumper_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('read_bumper_image', true) }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="read_bumper" value={windshield.read_bumper} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Right Headlight</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.right_headlight_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('right_headlight_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="right_headlight" value={windshield.right_headlight} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="OK" placeholder={undefined}>OK</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Left Headlight</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.left_headlight_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('left_headlight_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="left_headlight" value={windshield.left_headlight} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>OK</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Right Fog Light</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.right_fog_light_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('right_fog_light_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="right_fog_light" value={windshield.right_fog_light} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="OK" placeholder={undefined}>OK</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Left Fog Light</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.left_fog_light_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('left_fog_light_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="left_fog_light" value={windshield.left_fog_light} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="OK" placeholder={undefined}>OK</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Right Tail Light</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.right_tailight_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('right_tailight_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="right_tailight" value={windshield.right_tailight} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="OK" placeholder={undefined}>OK</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Left Tail Light</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.left_tailight_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('left_tailight_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect placeholder="ok" name="left_tailight" value={windshield.left_tailight} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="OK" placeholder={undefined}>OK</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <div className="modal-txtarea">
                        <IonTextarea name="comment" value={windshield.comment} onIonChange={(event: any) => { handleInputs(event) }} placeholder="Type something here"></IonTextarea>
                    </div>
                    <Staricons rating={parseInt(windshield.rating)} handleRating={updateRating} />
                    <IonButton onClick={async () => { await submitWindshieldInfo(); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
                </IonContent>
            </IonPage>
        </div>
    )
}

export default Windshield
