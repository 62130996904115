import './Carsinfo.css';
import React, { useState } from 'react';
import { IonList, IonInput, IonCard, IonCardContent } from '@ionic/react';
import { IonCheckbox, useIonToast, useIonViewDidEnter, IonButton, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonLoading } from '@ionic/react';
import {
    IonContent,
    IonPage,
} from '@ionic/react';
import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import IonIcon from '@reacticons/ionicons';
import { useHistory } from 'react-router';
import { getQueryParams } from '../../services/helper.service';
import { carinfo, completeprocess, startCarsInspection } from '../../services/cars.service';
import { imagelistingbyscreen } from '../../services/camera.service';
import { getData } from '../../services/localstorage.service';
import { Geolocation } from '@capacitor/geolocation';
import { Device } from '@capacitor/device';
import { format } from 'date-fns';

function Carsinfo() {
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const history = useHistory();
    const [presentToast] = useIonToast();
    const [car, setCar] = useState<any>({});

    const presentToastr = (message: string, color: string) => {
        presentToast({
            message: message,
            duration: 3000,
            position: 'top',
            color: color
        });
    }

    let [profileImages, setProfileImages] = useState<any[]>([]);

    let [insdata, setInsdata] = useState<any>({});
    const [currentPosition, setCurrentPosition] =useState<any>({});
    

    const handleInputs = async (e: any) => {
        let inputObj = {
            "name": e.target.name,
            "value": e.target.value
        };
        setInsdata({
            ...insdata,
            [inputObj.name]: inputObj.value
        });
    }

    const getCarinfo = () => {
        carinfo(getQueryParams('id')).then(carData => {
            console.log("carData", carData)
            if (carData.success) {
                if (carData.data.data.length == 0) {
                    history.goBack();
                }
                setCar(carData.data.data[0]);
                setInsdata({
                    ...insdata,
                    'expected_price': carData.data.data[0].cars.expected_price,
                    'inspection_remarks': carData.data.data[0].cars.inspection_remarks,
                    'ncd': carData.data.data[0].cars.ncd,
                });
                imagelistingbyscreen({
                    'screen_type': 'profile-picture',
                    'lead_id': getQueryParams('id')
                }).then(images => {
                    console.log("123")
                    console.log("first", images.data    )
                    if (images.data.length > 0) {
                        setProfileImages(images.data);
                    }
                });
            }
        });
    }

    useIonViewDidEnter(() => {
        getCarinfo();
        printCurrentPosition();
    });

    let submitreport = async () => {
        setShowLoading(true);
        let payload= {
            'id': getQueryParams('id'),
            'expected_price': insdata.expected_price,
            'inspection_remarks': insdata.inspection_remarks,
            'ncd': insdata.ncd,
            'end_device': currentPosition.device,
            'end_lat': currentPosition.lat,
            'end_lng': currentPosition.lng,
            'inspection_status': 2
        }
        
        let res: any = await completeprocess(payload);
        setShowLoading(false);
        if(res.success) {
            presentToastr(res.message, 'success');
            if( getData('aw') != '1') {
                history.replace('/cars');
            }
        } else {
            presentToastr(res.message, 'danger');
        }
    }
      
    const printCurrentPosition = async () => {
        try {
          const coordinates = await Geolocation.getCurrentPosition();
          const info = await Device.getInfo();
          setCurrentPosition({
            'lat': coordinates.coords.latitude,
            'lng': coordinates.coords.longitude,
            'device': `${info.manufacturer}, ${info.model}, ${info.operatingSystem}, ${info.platform}, ${info.osVersion}`,
          });
        } catch (error) {
          console.error('Error:', error);
        }
      };
      
    const startInspection = async () => {
        setShowLoading(true);
        let payload= {
            'car_id': getQueryParams('cid'),
            'start_device': currentPosition.device,
            'start_lat': currentPosition.lat,
            'start_lng': currentPosition.lng,
        }
        console.log("startCarsInspection", payload)
        let carsInspection = await startCarsInspection(payload);
        setShowLoading(false);
        if (carsInspection.success) {
            presentToastr(carsInspection.message, 'success');
            history.replace('/carsinfo?id=' + getQueryParams('id') + '&cid=' + getQueryParams('cid'));
        } else {
            presentToastr(carsInspection.message, 'danger');
        }
    }
    return (
        <>
            <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header pageTitle="Vehicle Registration" />
                <IonContent className="ion-padding" placeholder={undefined}>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                    />
                    <IonList placeholder={undefined}>
                        <div className="cars-infosearch">
                            <IonIcon className="carinfo-tgl" name="menu"></IonIcon>
                            <IonInput readonly placeholder="Inspector Report"></IonInput>
                            <span>LIVE</span><IonIcon className="carinfo-tglradio" name="radio" color="success"></IonIcon>
                        </div>
                    </IonList>
                    {(car?.cars?.car_inspection?.start_time == null) && <IonButton onClick={async () => { await startInspection(); } } className="startInspectionBtn" placeholder={undefined}>START INSPECTION</IonButton>}
                    {(car?.cars?.car_inspection?.start_time != null) && <h5>Inspection Start time</h5>}
                    {(car?.cars?.car_inspection?.start_time != null) && <IonButton onClick={async () => { await startInspection(); } } className="startInspectionBtn" placeholder={undefined}>{format(new Date(car?.cars?.car_inspection?.start_time), 'dd/MM/yyyy hh:mm a')}</IonButton>}
                    <IonCard onClick={() => { history.push('/basicinfo?id=' + getQueryParams('id') + '&cid=' + car?.cars?.id); } } className="info-cards" placeholder={undefined}>
                        <IonCardContent className='info-details' placeholder={undefined}>
                            <h3>Basic Information</h3>
                            <div className="info-icondetails">
                                <IonIcon className={(car?.cars?.basic_info_completed == '1') ? 'color-success' : ''} name="checkmark-circle-outline"></IonIcon>
                                <IonIcon className={(car?.cars?.basic_info_completed == '1') ? 'color-success' : ''} name="arrow-forward-circle-outline"></IonIcon>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <IonCard onClick={() => { history.push('/documentation?id=' + getQueryParams('id') + '&cid=' + car?.cars?.id); } } className="info-cards" placeholder={undefined}>
                        <IonCardContent className='info-details' placeholder={undefined}>
                            <h3>Documentation</h3>
                            <div className="info-icondetails">
                                <IonIcon className={(car?.cars?.documentation?.documentation_completed == '1') ? 'color-success' : ''} name="checkmark-circle-outline"></IonIcon>
                                <IonIcon className={(car?.cars?.documentation?.documentation_completed == '1') ? 'color-success' : ''} name="arrow-forward-circle-outline"></IonIcon>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <IonCard onClick={() => { history.push('/interior?id=' + getQueryParams('id') + '&cid=' + car?.cars?.id); } } className="info-cards" placeholder={undefined}>
                        <IonCardContent className='info-details' placeholder={undefined}>
                            <h3>Interior</h3>
                            <div className="info-icondetails">
                                <IonIcon className={(car?.cars?.interior?.interior_completed == '1') ? 'color-success' : ''} name="checkmark-circle-outline"></IonIcon>
                                <IonIcon className={(car?.cars?.interior?.interior_completed == '1') ? 'color-success' : ''} name="arrow-forward-circle-outline"></IonIcon>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <IonCard onClick={() => { history.push('/exterior?id=' + getQueryParams('id') + '&cid=' + car?.cars?.id); } } className="info-cards" placeholder={undefined}>
                        <IonCardContent className='info-details' placeholder={undefined}>
                            <h3>Exterior</h3>
                            <div className="info-icondetails">
                                <IonIcon className={(car?.cars?.exterior?.exterior_completed == '1') ? 'color-success' : ''} name="checkmark-circle-outline"></IonIcon>
                                <IonIcon className={(car?.cars?.exterior?.exterior_completed == '1') ? 'color-success' : ''} name="arrow-forward-circle-outline"></IonIcon>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <IonCard onClick={() => { history.push('/tyres?id=' + getQueryParams('id') + '&cid=' + car?.cars?.id); } } className="info-cards" placeholder={undefined}>
                        <IonCardContent className='info-details' placeholder={undefined}>
                            <h3>Exterior/ Tyre</h3>
                            <div className="info-icondetails">
                                <IonIcon className={(car?.cars?.exterior_tyre?.exterior_tyre_completed == '1') ? 'color-success' : ''} name="checkmark-circle-outline"></IonIcon>
                                <IonIcon className={(car?.cars?.exterior_tyre?.exterior_tyre_completed == '1') ? 'color-success' : ''} name="arrow-forward-circle-outline"></IonIcon>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <IonCard onClick={() => { history.push('/windshield?id=' + getQueryParams('id') + '&cid=' + car?.cars?.id); } } className="info-cards" placeholder={undefined}>
                        <IonCardContent className='info-details' placeholder={undefined}>
                            <h3>Windshield and Light</h3>
                            <div className="info-icondetails">
                                <IonIcon className={(car?.cars?.windshield_light?.wind_shield_light_completed == '1') ? 'color-success' : ''} name="checkmark-circle-outline"></IonIcon>
                                <IonIcon className={(car?.cars?.windshield_light?.wind_shield_light_completed == '1') ? 'color-success' : ''} name="arrow-forward-circle-outline"></IonIcon>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <IonCard onClick={() => { history.push('/engine?id=' + getQueryParams('id') + '&cid=' + car?.cars?.id); } } className="info-cards" placeholder={undefined}>
                        <IonCardContent className='info-details' placeholder={undefined}>
                            <h3>Engine</h3>
                            <div className="info-icondetails">
                                <IonIcon className={(car?.cars?.engine?.engine_completed == '1') ? 'color-success' : ''} name="checkmark-circle-outline"></IonIcon>
                                <IonIcon className={(car?.cars?.engine?.engine_completed == '1') ? 'color-success' : ''} name="arrow-forward-circle-outline"></IonIcon>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <IonCard onClick={() => { history.push('/ac?id=' + getQueryParams('id') + '&cid=' + car?.cars?.id); } } className="info-cards" placeholder={undefined}>
                        <IonCardContent className='info-details' placeholder={undefined}>
                            <h3>AC and Heater</h3>
                            <div className="info-icondetails">
                                <IonIcon className={(car?.cars?.ac_and_heater?.ac_and_heater_completed == '1') ? 'color-success' : ''} name="checkmark-circle-outline"></IonIcon>
                                <IonIcon className={(car?.cars?.ac_and_heater?.ac_and_heater_completed == '1') ? 'color-success' : ''} name="arrow-forward-circle-outline"></IonIcon>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <IonCard onClick={() => { history.push('/electrical?id=' + getQueryParams('id') + '&cid=' + car?.cars?.id); } } className="info-cards" placeholder={undefined}>
                        <IonCardContent className='info-details' placeholder={undefined}>
                            <h3>Electrical</h3>
                            <div className="info-icondetails">
                                <IonIcon className={(car?.cars?.electrical?.electrical_completed == '1') ? 'color-success' : ''} name="checkmark-circle-outline"></IonIcon>
                                <IonIcon className={(car?.cars?.electrical?.electrical_completed == '1') ? 'color-success' : ''} name="arrow-forward-circle-outline"></IonIcon>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <IonCard onClick={() => { history.push('/steering?id=' + getQueryParams('id') + '&cid=' + car?.cars?.id); } } className="info-cards" placeholder={undefined}>
                        <IonCardContent className='info-details' placeholder={undefined}>
                            <h3>Steering and Suspension</h3>
                            <div className="info-icondetails">
                                <IonIcon className={(car?.cars?.steering_and_suspension?.steering_and_suspension_completed == '1') ? 'color-success' : ''} name="checkmark-circle-outline"></IonIcon>
                                <IonIcon className={(car?.cars?.steering_and_suspension?.steering_and_suspension_completed == '1') ? 'color-success' : ''} name="arrow-forward-circle-outline"></IonIcon>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <IonCard onClick={() => { history.push('/carprofile?id=' + getQueryParams('id') + '&cid=' + car?.cars?.id); } } className="info-cards" placeholder={undefined}>
                        <IonCardContent className='info-details' placeholder={undefined}>
                            <h3>Profile photo</h3>
                            <div className="info-icondetails">
                                <IonIcon className={(profileImages.length >= 6) ? 'color-success' : ''} name="checkmark-circle-outline"></IonIcon>
                                <IonIcon className={(profileImages.length >= 6) ? 'color-success' : ''} name="arrow-forward-circle-outline"></IonIcon>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <IonCard onClick={() => { history.push('/seller-docs?id=' + getQueryParams('id') + '&cid=' + car?.cars?.id); } } className="info-cards" placeholder={undefined}>
                        <IonCardContent className='info-details' placeholder={undefined}>
                            <h3>Upload Seller Documents</h3>
                        </IonCardContent>
                    </IonCard>
                    <IonCard onClick={() => { history.push('/uploaded-images?id=' + getQueryParams('id') + '&cid=' + car?.cars?.id); } } className="info-cards" placeholder={undefined}>
                        <IonCardContent className='info-details' placeholder={undefined}>
                            <h3>Review Uploaded Images</h3>
                        </IonCardContent>
                    </IonCard>
                    <div>
                        <p><strong>Note: Complete section report and carefully inspection under by inspector.</strong></p>
                    </div>
                    <IonList placeholder={undefined}>
                        <div className="inputArea">
                            <IonIcon name="car" />
                            <IonInput type="text" name="expected_price" onIonChange={(event)=>{handleInputs(event)}} value={insdata.expected_price} placeholder="Enter client demand amount"></IonInput>
                        </div>
                        <div className="inputArea">
                            <IonIcon name="create-outline" />
                            <IonInput type="text" name="inspection_remarks" onIonChange={(event)=>{handleInputs(event)}} value={insdata.inspection_remarks} placeholder="Enter remark"></IonInput>
                        </div>
                        <div className="inputArea">
                            <IonIcon name="disc-outline" />
                            <IonInput type="text" name="ncd" onIonChange={(event)=>{handleInputs(event)}} value={insdata.ncd} placeholder="Enter NCD"></IonInput>
                        </div>
                    </IonList>
                    {/* <p>By Completing and Submitting this from you agree to the terms and conditions.</p>
                    <div className='auth-terms-check'>
                        <IonCheckbox slot="start"></IonCheckbox>
                        <span> I agree to the <a href="javascript:void(0)" onClick={() => setIsTermsModalOpen(true)}><b>terms and conditions</b></a></span></div> */}
                    <IonButton onClick={async () => { await submitreport(); } } className="lgnBtn" expand="block" placeholder={undefined}>COMPLETE INSPECTION</IonButton>

                    <IonModal isOpen={isTermsModalOpen} placeholder={undefined}>
                        <IonHeader placeholder={undefined}>
                            <IonToolbar placeholder={undefined}>
                                <IonTitle placeholder={undefined}>Terms and Condition</IonTitle>
                                <IonButtons slot="end" placeholder={undefined}>
                                    <IonButton onClick={() => setIsTermsModalOpen(false)} placeholder={undefined}>CLOSE</IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding" placeholder={undefined}>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni illum quidem recusandae ducimus quos
                                reprehenderit. Veniam, molestias quos, dolorum consequuntur nisi deserunt omnis id illo sit cum qui.
                                Eaque, dicta.
                            </p>
                        </IonContent>
                    </IonModal>
                </IonContent>

            </IonPage>
        </>
    );
}
export default Carsinfo;