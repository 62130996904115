import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IonContent, IonPage, IonInput, IonList, IonLoading, useIonToast } from '@ionic/react';
import { Link } from "react-router-dom";
import './Login.css';
import { IonButton } from '@ionic/react';
import IonIcon from '@reacticons/ionicons';
import { login, loginInterface } from '../../../services/auth.service';
import { userprofile } from '../../../services/users.services';
import { setData } from '../../../services/localstorage.service';
import { redirectifAuth } from '../../../services/guard.service';

const Login: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [presentToast] = useIonToast();

  const presentToastr = (message: string, color: string) => {
    presentToast({
      message: message,
      duration: 3000,
      position: 'top',
      color: color
    });
  }

  const [loginDetails, setLoginDetails] = useState<loginInterface>({
    email: "",
    password: "",
  });

  const history = useHistory();

  if (redirectifAuth()) {
    history.replace('/cars');
  }

  const handleSubmit = async () => {
    setShowLoading(true)
    const loginRes = await login(loginDetails);
    setShowLoading(false);
    if (loginRes.success) {
      await setData('token', loginRes.data.authToken);

      const userprofileData = await userprofile();
      if (userprofileData.success) {
        await setData('userprofile_details', JSON.stringify(userprofileData.data));
      }
      presentToastr(loginRes.message, 'success');
      history.replace('/cars');
    } else {
      presentToastr(loginRes.message, 'danger');
    }
  }

  return (
    <IonPage placeholder={undefined}>
      <IonContent fullscreen placeholder={undefined} >

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Loading...'}
        />

        <div className="logo"><img src="/assets/images/login.png" alt="" /></div>
        <div className='bg-container'>

          <IonList className="lgnheading" placeholder={undefined}>
            <div className="main-content">
              <div>
                <h2><b>Welcome back!</b></h2>
                <p>Hello there, login to continue</p>
              </div>
              <div className="inputArea">
                <IonIcon name="person" />
                <IonInput clearOnEdit={true} type="email" value={loginDetails.email} onIonInput={(e) => { setLoginDetails({ ...loginDetails, email: e.target.value }) }} placeholder="Enter username"></IonInput>
              </div>
              <div className="inputArea">
                <IonIcon name="lock-closed" />
                <IonInput type="password" value={loginDetails.password} onIonInput={(e) => { setLoginDetails({ ...loginDetails, password: e.target.value }) }} placeholder="Enter password"></IonInput>
              </div>
              <div>
                <div className='auth-terms-check'>
                  <span> Lost your password? <a onClick={() => { history.push('/forgetpswd') }}><b>Forgot Password?</b></a></span>
                </div>
              </div>
              <IonButton onClick={() => { handleSubmit(); } } className="lgnBtn" expand="block" placeholder={undefined}>Login</IonButton>
              <div className="ion-text-center ion-padding">Don't have an account?<Link to="/register"><b> Sign Up</b></Link></div>
            </div>
          </IonList>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;