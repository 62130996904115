import './Electrical.css';
import {
    IonCard,
    IonPage,
    IonContent,
    IonCardHeader,
    IonCardTitle,
    IonSelectOption,
    IonSelect,
    IonTextarea,
    IonButton,
    useIonToast,
    IonLoading,
    useIonViewDidEnter
} from '@ionic/react';
import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import IonIcon from '@reacticons/ionicons';
import { useHistory } from 'react-router';
import { useState } from 'react'
import Staricons from '../../components/staricons/Staricons';
import { updateelectrical, carinfo } from '../../services/cars.service';
import { getQueryParams } from '../../services/helper.service';
import { getData } from '../../services/localstorage.service';
import { imagelistingbyscreen } from '../../services/camera.service';
import { takePicture, uploadimage } from '../../services/camera.service';

function Electrical() {
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);
    const [presentToast] = useIonToast();

    const presentToastr = (message: string, color: string) => {
        presentToast({
            message: message,
            duration: 3000,
            position: 'top',
            color: color
        });
    }

    const [electrical, setElectrical] = useState<any>({
        'car_id': '',
        'lead_id': getQueryParams('id'),
        'inspector_id': '',
        'airbag': '',
        'no_of_airbag': '',
        'sunroof_window': '',
        'right_front_power_windows': '',
        'left_front_power_windows': '',
        'right_rear_power_windows': '',
        'left_rear_power_windows': '',
        'music_system': '',
        'reverse_camera': '',
        'seat_type': '',
        'seat_condition': '',
        'front_wriper': '',
        'rear_wriper': '',
        'inner_view_mirror': '',
        'steering_audio_control': '',
        'tachnometer_control': '',
        'central_locking': '',
        'horn': '',
        'speedo_meter': '',
        'odometer': '',
        'carpet': '',
        'glove_box': '',
        'seat_belt': '',
        'gps_chip': '',
        'comments': '',
        'rating': '0',
        'electrical_completed': '1',
    })

    // const toggleModal = (status: boolean) => {
    //     setupdateImages({
    //         ...inspectionImageMeta,
    //         'open': status
    //     })
    // }

    // const [inspectionImageMeta, setupdateImages] = useState<any>({
    //     'title': '',
    //     'open': false,
    //     'imgtype': '',
    //     'pagetype': '',
    //     'car_id': electrical.car_id,
    //     'lead_id': getQueryParams('id'),
    //     'toggleModal': toggleModal
    // })

    // const handleImages = async (open: boolean, imgtype: string, pagetype: string, title: string) => {
    //     setupdateImages({
    //         ...inspectionImageMeta,
    //         'car_id': electrical.car_id,
    //         'lead_id': getQueryParams('id'),
    //         'open': open,
    //         'imgtype': imgtype,
    //         'pagetype': pagetype,
    //         'title': title,
    //     });
    // }

    let [imagesObj, setImagesObj] = useState<any>({});

    let listImages = () => {
        imagelistingbyscreen({
            'screen_type': 'electrical',
            "car_id": getQueryParams('cid'),
            'lead_id': getQueryParams('id')
        }).then(images => {
            if (images.data.length > 0) {
                let imageMap: any = {};
                images.data.map((image: any) => {
                    imageMap[image.image_type] = image.path;
                })
                setImagesObj({
                    ...imagesObj,
                    ...imageMap
                });
            }
        });
    }

    const handleImageUpload = async (imageType: string, edit: boolean = false) => {
        let imgData = await takePicture(edit);
        if (imgData) {
            setShowLoading(true);
            await uploadimage({
                "car_id": getQueryParams('cid'),
                "lead_id": getQueryParams('id'),
                [imageType]: imgData
            });
            setShowLoading(false);
        }
        listImages();
    };

    const handleInputs = async (e: any) => {
        let inputObj = {
            "name": e.target.name,
            "value": e.target.value,
        };
        setElectrical({
            ...electrical,
            [inputObj.name]: inputObj.value,
        });
    }

    const getCarinfo = async () => {
        let carData: any = await carinfo(getQueryParams('id'));
        if (carData.success) {
            if (carData.data.data.length == 0) {
                presentToastr('Car not found', 'danger');
                history.goBack();
            }
            let carelectrical = carData.data.data[0].cars.electrical;
            let inspectorData = JSON.parse(getData('userprofile_details'));
            setElectrical({
                ...electrical,
                car_id: carData.data.data[0].cars.id,
                inspector_id: inspectorData.id
            });
            if (carelectrical != null) {
                setElectrical({
                    ...electrical,
                    'id': carelectrical.id,
                    'car_id': carData.data.data[0].cars.id,
                    'lead_id': getQueryParams('id'),
                    'inspector_id': inspectorData.id,
                    'airbag': carelectrical.airbag,
                    'no_of_airbag': carelectrical.no_of_airbag,
                    'sunroof_window': carelectrical.sunroof_window,
                    'right_front_power_windows': carelectrical.right_front_power_windows,
                    'left_front_power_windows': carelectrical.left_front_power_windows,
                    'right_rear_power_windows': carelectrical.right_rear_power_windows,
                    'left_rear_power_windows': carelectrical.left_rear_power_windows,
                    'music_system': carelectrical.music_system,
                    'reverse_camera': carelectrical.reverse_camera,
                    'seat_type': carelectrical.seat_type,
                    'seat_condition': carelectrical.seat_condition,
                    'front_wriper': carelectrical.front_wriper,
                    'rear_wriper': carelectrical.rear_wriper,
                    'inner_view_mirror': carelectrical.inner_view_mirror,
                    'steering_audio_control': carelectrical.steering_audio_control,
                    'tachnometer_control': carelectrical.tachnometer_control,
                    'central_locking': carelectrical.central_locking,
                    'horn': carelectrical.horn,
                    'speedo_meter': carelectrical.speedo_meter,
                    'odometer': carelectrical.odometer,
                    'carpet': carelectrical.carpet,
                    'glove_box': carelectrical.glove_box,
                    'seat_belt': carelectrical.seat_belt,
                    'gps_chip': carelectrical.gps_chip,
                    'comments': carelectrical.comments,
                    'rating': carelectrical.rating
                });
            }
        }
    }

    const submitElectricalInfo = async () => {
        setShowLoading(true);
        let updateelectric = await updateelectrical(electrical);
        setShowLoading(false);
        if (updateelectric.success) {
            presentToastr(updateelectric.message, 'success');
            history.replace('/carsinfo?id=' + getQueryParams('id'));
        } else {
            presentToastr(updateelectric.message, 'danger');
        }
    }

    const updateRating = (rating: any) => {
        setElectrical({
            ...electrical,
            'rating': rating
        });
    }

    useIonViewDidEnter(async () => {
        setShowLoading(true);
        await getCarinfo();
        setShowLoading(false);
        listImages();
    });

    return (
        <div>
            <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header pageTitle="Electrical" />
                <IonContent className="ion-padding" placeholder={undefined}>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                    />
                    <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className="airbags-cards">
                            <div className="airbag-innercards">
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Airbag</IonCardTitle></IonCardHeader>
                                <IonSelect name="airbag" value={electrical.airbag} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Yes" placeholder={undefined}>Yes</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="No" placeholder={undefined}>No</IonSelectOption>
                                </IonSelect></div>
                            <div className="airbag-innercards">
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>No Of Airbag</IonCardTitle></IonCardHeader>
                                <IonSelect name="no_of_airbag" value={electrical.no_of_airbag} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption value="1" placeholder={undefined}>1</IonSelectOption>
                                    <IonSelectOption value="2" placeholder={undefined}>2</IonSelectOption>
                                    <IonSelectOption value="4" placeholder={undefined}>4</IonSelectOption>
                                    <IonSelectOption value="8" placeholder={undefined}>8</IonSelectOption>
                                </IonSelect></div>
                        </div>
                    </IonCard>
                    <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className="airbags-cards">
                            <div className="airbag-innercards">
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Sunroof Window</IonCardTitle></IonCardHeader>
                                <IonSelect name="sunroof_window" value={electrical.sunroof_window} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-danger" value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                    <IonSelectOption className="color-success" value="Panaromic Sunroof" placeholder={undefined}>Panaromic Sunroof</IonSelectOption>
                                    <IonSelectOption className="color-success" value="Sunroof" placeholder={undefined}>Sunroof</IonSelectOption>
                                </IonSelect></div>
                            <div className="airbag-innercards">
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Right Front Power Windows</IonCardTitle></IonCardHeader>
                                <IonSelect name="right_front_power_windows" value={electrical.right_front_power_windows} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect></div>
                        </div>
                    </IonCard>

                    <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className="airbags-cards">
                            <div className="airbag-innercards">
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Left Front Power Windows</IonCardTitle></IonCardHeader>
                                <IonSelect name="left_front_power_windows" value={electrical.left_front_power_windows} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect></div>
                            <div className="airbag-innercards">
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Right Rear Power Windows</IonCardTitle></IonCardHeader>
                                <IonSelect name="right_rear_power_windows" value={electrical.right_rear_power_windows} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect></div>
                        </div>
                    </IonCard>
                    <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className="airbags-cards">
                            <div className="airbag-innercards">
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Left Rear Power Windows</IonCardTitle></IonCardHeader>
                                <IonSelect name="left_rear_power_windows" value={electrical.left_rear_power_windows} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect></div>
                            <div className="airbag-innercards">
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Music System</IonCardTitle></IonCardHeader>
                                <IonSelect name="music_system" value={electrical.music_system} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect></div>
                        </div>
                    </IonCard>

                    <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className="airbags-cards">
                            <div className="airbag-innercards">
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Reverse Camera</IonCardTitle></IonCardHeader>
                                <IonSelect name="reverse_camera" value={electrical.reverse_camera} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect></div>
                            <div className="airbag-innercards">
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Seat Type</IonCardTitle></IonCardHeader>
                                <IonSelect name="seat_type" value={electrical.seat_type} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption value="Fabric" placeholder={undefined}>Fabric Seat</IonSelectOption>
                                    <IonSelectOption value="Leather" placeholder={undefined}>Leather Seat</IonSelectOption>
                                </IonSelect></div>
                        </div>
                    </IonCard>

                    <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className='airbags-cards'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Seat Condition</IonCardTitle></IonCardHeader>
                                <IonSelect name="seat_condition" value={electrical.seat_condition} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Torn" placeholder={undefined}>Torn</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                </IonSelect>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Front Wriper</IonCardTitle></IonCardHeader>
                                <IonSelect name="front_wriper" value={electrical.front_wriper} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect>
                            </div>
                        </div>
                    </IonCard>

                    <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className='airbags-cards'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Rear Wiper</IonCardTitle></IonCardHeader>
                                <IonSelect name="rear_wriper" value={electrical.rear_wriper} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Inner View Mirror</IonCardTitle></IonCardHeader>
                                <IonSelect name="inner_view_mirror" value={electrical.inner_view_mirror} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="OK" placeholder={undefined}>OK</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damage" placeholder={undefined}>Damage</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect>
                            </div>
                        </div>
                    </IonCard>
                    <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Steering Audio Control</IonCardTitle></IonCardHeader>
                                <IonSelect name="steering_audio_control" value={electrical.steering_audio_control} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Yes" placeholder={undefined}>Yes</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="No" placeholder={undefined}>No</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Tachnometer Control</IonCardTitle></IonCardHeader>
                                <IonSelect name="tachnometer_control" value={electrical.tachnometer_control} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Yes" placeholder={undefined}>Yes</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="No" placeholder={undefined}>No</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect>
                            </div>
                        </div>
                    </IonCard>

                    <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Central Locking</IonCardTitle></IonCardHeader>
                                <IonSelect name="central_locking" value={electrical.central_locking} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Horn</IonCardTitle></IonCardHeader>
                                <IonSelect name="horn" value={electrical.horn} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect>
                            </div>
                        </div>
                    </IonCard>
                    <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Speedo Meter</IonCardTitle></IonCardHeader>
                                <IonSelect name="speedo_meter" value={electrical.speedo_meter} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Working" placeholder={undefined}>Working</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Working" placeholder={undefined}>Not Working</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect>
                            </div>
                            <div>
                                <IonCardHeader className='exterior-cardheader' placeholder={undefined}><IonCardTitle className='exterior-cardtitle' placeholder={undefined}>Odometer</IonCardTitle>
                                <IonIcon className={(imagesObj.odometer_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('odometer_image') }} name="camera"></IonIcon></IonCardHeader>
                                <IonSelect name="odometer" value={electrical.odometer} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption value="Tempered" placeholder={undefined}>Tempered</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect>
                            </div>
                        </div>
                    </IonCard>
                    <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className='carsmfg-year'>
                            <div>
                                <IonCardHeader className='exterior-cardheader' placeholder={undefined}><IonCardTitle className='exterior-cardtitle' placeholder={undefined}>Carpet</IonCardTitle>
                                <IonIcon className={(imagesObj.carpet_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('carpet_image') }} name="camera"></IonIcon></IonCardHeader>
                                <IonSelect name="carpet" value={electrical.carpet} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption value="OK" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption value="Torn" placeholder={undefined}>Torn</IonSelectOption>
                                    <IonSelectOption value="Not Clean" placeholder={undefined}>Not Clean</IonSelectOption>
                                </IonSelect>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Glove Box</IonCardTitle></IonCardHeader>
                                <IonSelect name="glove_box" value={electrical.glove_box} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Ok" placeholder={undefined}>Not Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect>
                            </div>
                        </div>
                    </IonCard>
                    <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className='airbags-cards'>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Seat Belt</IonCardTitle></IonCardHeader>
                                <IonSelect name="seat_belt" value={electrical.seat_belt} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect>
                            </div>
                            <div>
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>GPS Chip</IonCardTitle></IonCardHeader>
                                <IonSelect name="gps_chip" value={electrical.gps_chip} placeholder="ok" onIonDismiss={async (event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="OK" placeholder={undefined}>OK</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Lost" placeholder={undefined}>Lost</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect>
                            </div>
                        </div>
                    </IonCard>
                    <div className="Elec-txtarea">
                        <div className="modal-txtarea">
                            <IonTextarea name="comments" value={electrical.comments} placeholder="Enter Remark" onIonChange={(event: any) => { handleInputs(event) }}></IonTextarea>
                        </div>
                        <Staricons rating={parseInt(electrical.rating)} handleRating={updateRating} />
                        <IonButton onClick={async () => { await submitElectricalInfo(); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
                    </div>
                </IonContent>
            </IonPage>
        </div>
    )
}

export default Electrical