import './Cars.css';
import {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonButton,
    IonCol,
    IonGrid,
    IonRow,
    IonContent,
    IonSearchbar,
    IonPage,
    IonLoading,
    IonInfiniteScrollContent,
    IonInfiniteScroll,
    useIonViewDidEnter, IonRefresher, IonRefresherContent, RefresherEventDetail,
    IonLabel,
} from '@ionic/react';
import IonIcon from '@reacticons/ionicons';
import { useHistory } from 'react-router-dom';
import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import { carlist } from '../../services/cars.service';
import { useState } from 'react';
import { getData } from '../../services/localstorage.service';
import { format } from 'date-fns';
import { debounce } from 'lodash';

function Cars() {
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);

    const [page, setPage] = useState<number>(1);
    const [cars, setCars] = useState<any[]>([]);
    const [allLoaded, setAllLoaded] = useState<boolean>(false);
    const [nocarsmsg, setnocarsmsg] = useState<string>('');

    function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
        setnocarsmsg('');
        setAllLoaded(false);
        setPage(1);
        getCarList(1, srch, true);
        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    const getCarList = (pageNo: number, search: string, forceGet: boolean = false) => {
        if (!allLoaded || forceGet) {
            setShowLoading(true);
            let userData: any = JSON.parse(getData('userprofile_details'));
            if (userData == null) {
                setTimeout(() => {
                    getCarList(pageNo, search);
                }, 1000);
                return false;
            }
            carlist(pageNo, userData.id, search).then(carData => {
                setShowLoading(false);
                if (carData.success) {
                    if (carData.data.data.length == 0) {
                        setnocarsmsg('No more cars found!');
                        setAllLoaded(true);
                    }
                    if (pageNo > 1) {
                        setCars([...cars, ...carData.data.data]);
                    } else {
                        setCars(carData.data.data);
                    }
                    setPage(pageNo + 1);
                }
            }).catch((err)=>{
                setShowLoading(false);
            });
        }
    }

    const [srch, setSrch] = useState<any>("")
    // const handleSearch = (event: any) => {
    //     setnocarsmsg('');
    //     setSrch(event.target.value);
    //     setAllLoaded(false);
    //     setPage(1);
    //     getCarList(1, event.target.value, true);
    // }
    const handleSearch =  debounce((event: any) => {
        setnocarsmsg('');
        setSrch(event.target.value);
        setAllLoaded(false);
        setPage(1);
        getCarList(1, event.target.value, true);
    },1000)

    useIonViewDidEnter(() => {
        getCarList(page, srch);
    });

    return (
        <>
            <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header showBackButton={true} pageTitle="Home" />
                <IonContent className="ion-padding" placeholder={undefined}>
                    <IonRefresher slot="fixed" onIonRefresh={(event) => { handleRefresh(event); } } placeholder={undefined}>
                        <IonRefresherContent placeholder={undefined}></IonRefresherContent>
                    </IonRefresher>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                    />
                    <IonSearchbar className="car-search" placeholder="Search register no." value={srch} onIonInput={(event) => { handleSearch(event) }}></IonSearchbar>
                    <IonGrid placeholder={undefined}>
                        <IonRow placeholder={undefined}>
                            <IonCol placeholder={undefined}>
                                {cars.map(car => {
                                    return (<IonCard key={car.id} onClick={() => { history.push('/carsinfo?id=' + car.id + '&cid=' + car?.cars?.id); } } className="ins-car-list" placeholder={undefined}>
                                        <IonCardHeader className='ins-details' placeholder={undefined}>
                                            <IonCardSubtitle placeholder={undefined}>Car : {car?.cars?.brand?.name} {car?.cars?.model?.name} {car?.cars?.varient?.name} ({car?.cars?.make_year})</IonCardSubtitle>
                                            <IonCardSubtitle placeholder={undefined}>Name : {car.user.name}</IonCardSubtitle>
                                            <IonCardSubtitle placeholder={undefined}>Phone : {car.user.phone}</IonCardSubtitle>
                                            <IonCardSubtitle placeholder={undefined}>Address : {car.cars.address}</IonCardSubtitle>
                                            <IonCardSubtitle placeholder={undefined}>Ins Date : {format(new Date(car.inspection_date), 'dd/MM/yyyy hh:mm a')}</IonCardSubtitle>
                                            <IonCardTitle placeholder={undefined}>{car.cars.registration_no}</IonCardTitle>
                                            <div className="car-icon">
                                                <IonIcon className={(car.cars.inspection_status != 1) ? 'color-success' : ''} name="checkmark-circle-outline"></IonIcon>
                                                <IonIcon className={(car.cars.inspection_status != 1) ? 'color-success' : ''} name="car"></IonIcon>
                                            </div>
                                        </IonCardHeader>
                                    </IonCard>)
                                })}
                                <IonLabel placeholder={undefined}>{nocarsmsg}</IonLabel>
                            </IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={(ev) => {
                                    if (!allLoaded) {
                                        getCarList(page, srch);
                                        setTimeout(() => ev.target.complete(), 500);
                                    } else {
                                        ev.target.complete();
                                    }
                                } } placeholder={undefined}                            >
                                <IonInfiniteScrollContent placeholder={undefined}></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonRow>
                    </IonGrid>
                </IonContent>
                <div onClick={()=>{history.push('/basicinfo?id=0&cid=0')}} className = "registration-btn">
                        <IonButton expand="full" placeholder={undefined}><IonIcon name="car"></IonIcon>
                        <h4>Vehicle Registration</h4>
                        <IonIcon name="chevron-forward-outline"></IonIcon></IonButton></div>
            </IonPage>
        </>
    );
}
export default Cars;