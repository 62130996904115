import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IonContent, IonPage, IonInput, IonList, IonCheckbox, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonLoading, useIonToast } from '@ionic/react';
import { Link } from "react-router-dom";
import './Forgetpswd.css';
import { IonButton } from '@ionic/react';
import IonIcon from '@reacticons/ionicons';
import { forgotpsw, forgotpswInterface } from '../../../services/auth.service';

const Forgotpswd: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [presentToast] = useIonToast();

  const presentToastr = (message: string, color: string) => {
    presentToast({
      message: message,
      duration: 3000,
      position: 'top',
      color: color
    });
  }

  const [forgotpswDetails, setForgotpswDetails] = useState<forgotpswInterface>({
    email: ""
  });

  const history = useHistory();

  const handleSubmit = async () => {
    setShowLoading(true)
    const forgetpswRes = await forgotpsw(forgotpswDetails);
    setShowLoading(false);
    if(forgetpswRes.success) {
      presentToastr(forgetpswRes.message, 'success');
      history.push('/resetpassword?email='+forgotpswDetails.email);
    }else {
        presentToastr(forgetpswRes.message, 'danger');
    }
  }

  return (
    <IonPage placeholder={undefined}>
      <IonContent fullscreen placeholder={undefined} >

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Loading...'}
        />

        <div className="logo"><img src="/assets/images/login.png" alt="" /></div>
        <div className='bg-container'>

          <IonList className="lgnheading" placeholder={undefined}>
            <div className="main-content">
              <div>
                <h2><b>Forgot your Password?</b></h2>
                <p>Please enter the email address you'd like your password reset Information sent to</p>
              </div>
              <div className="inputArea">
                <IonIcon name="person" />
                <IonInput clearOnEdit={true} type="email" value={forgotpswDetails.email} onIonInput={(e)=>{setForgotpswDetails({...forgotpswDetails, email: e.target.value})}} placeholder="Enter email"></IonInput>
              </div>
              <IonButton onClick={() => { handleSubmit(); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
              <div className="ion-text-center ion-padding">Don't have an account?<Link to="/register"><b> Sign Up</b></Link></div>
            </div>
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Forgotpswd;