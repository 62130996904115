import React, { useState } from 'react';
import './Insreport.css';
import Header from '../../components/header/Header';
import Navmenu from '../../components/navmenu/Navmenu';
import {
    IonContent,
    IonPage,
} from '@ionic/react';
import IonIcon from '@reacticons/ionicons';
import { IonInput, IonList, IonCheckbox, IonButton, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons } from '@ionic/react';
import { useHistory } from 'react-router';
import { getQueryParams } from '../../services/helper.service';

function Insreport() {
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const history = useHistory();

    return (
        <>
            <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header pageTitle="Vehicle Registration" />
                <IonContent className="ion-padding" placeholder={undefined}>
                    <div className='ins-report'>
                        <IonIcon className="insreport-icon" name="newspaper"></IonIcon>
                        <div><h1>INSPECTOR REPORT</h1>
                            <p>Vehicle registration number</p>
                        </div>
                    </div>
                    <IonList placeholder={undefined}>
                        <div className="ins-inputsearch">
                            <img src="/assets/images/flaglogo.png" alt="" />
                            <IonInput placeholder="____________________________"></IonInput>
                        </div>
                    </IonList>
                    <div><p>Note: Enter Vehicle registration number for inspection report. <br />eg. DL XX XXXX</p></div>
                    <div>
                        <p>By Completing and Submitting this from you agree to the terms and conditions.</p>
                        <div className='auth-terms-check'>
                            <IonCheckbox slot="start" placeholder={undefined}></IonCheckbox>
                            <span> I agree to the <a onClick={() => setIsTermsModalOpen(true)}><b>terms and conditions</b></a></span></div>
                    </div>
                    <IonButton onClick={() => { history.push('/carsinfo?id=' + getQueryParams('id')); } } className="lgnBtn" expand="block" placeholder={undefined}>NEXT</IonButton>
                </IonContent>
                <IonModal isOpen={isTermsModalOpen} placeholder={undefined}>
                    <IonHeader placeholder={undefined}>
                        <IonToolbar placeholder={undefined}>
                            <IonTitle placeholder={undefined}>Terms and Condition</IonTitle>
                            <IonButtons slot="end" placeholder={undefined}>
                                <IonButton onClick={() => setIsTermsModalOpen(false)} placeholder={undefined}>CLOSE</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding" placeholder={undefined}>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni illum quidem recusandae ducimus quos
                            reprehenderit. Veniam, molestias quos, dolorum consequuntur nisi deserunt omnis id illo sit cum qui.
                            Eaque, dicta.
                        </p>
                    </IonContent>
                </IonModal>
            </IonPage>
        </>
    )
}

export default Insreport;