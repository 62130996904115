export const setData = async (key: string, value: any)=>{
    await localStorage.setItem(key, value);
}

export const getData: any = (key: string)=>{
   return localStorage.getItem(key);
}

export const removeData = async (key: string)=>{
    await localStorage.removeItem(key);
}