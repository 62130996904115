import './Exterior.css';
import { useState } from 'react';
import { getQueryParams, parsejsonstring } from '../../services/helper.service';
import {
    IonCard,
    IonCardContent,
    IonPage,
    IonContent,
    IonCardHeader,
    IonCardTitle,
    IonSelectOption,
    IonSelect,
    IonTextarea,
    useIonToast,
    useIonViewDidEnter,
    IonLoading
} from '@ionic/react';
import { updateexterior, carinfo } from '../../services/cars.service';
import Header from '../../components/header/Header';
import Navmenu from '../../components/navmenu/Navmenu';
import IonIcon from '@reacticons/ionicons';
import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import { getData } from '../../services/localstorage.service';
import Staricons from '../../components/staricons/Staricons';
import { imagelistingbyscreen } from '../../services/camera.service';
import { takePicture, uploadimage } from '../../services/camera.service';

function Exterior() {

    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);
    const [presentToast] = useIonToast();

    const presentToastr = (message: string, color: string) => {
        presentToast({
            message: message,
            duration: 3000,
            position: 'top',
            color: color
        });
    }

    const [exterior, setExterior] = useState<any>({
        'id': '',
        'car_id': '',
        'lead_id': getQueryParams('id'),
        'inspector_id': '',
        'right_pillar_a': '',
        'right_pillar_b': '',
        'right_pillar_c': '',
        'left_pillar_a': '',
        'left_pillar_b': '',
        'left_pillar_c': '',
        'left_apron': '',
        'right_apron': '',
        'boot_floor': '',
        'cowl_top': '',
        'bonnet_hood': '',
        'roof': '',
        'dicky_boot_door': '',
        'left_door_front': '',
        'right_fender': '',
        'left_qtr_panel': '',
        'right_qtr_panel': '',
        'left_running_border': '',
        'right_running_border': '',
        'lower_cross_member': '',
        'upper_cross_member': '',
        'headlight_support': '',
        'headlight_support_right': '',
        'radiator_support': '',
        'firewall': '',
        'rating': 0,
        'exterior_completed': '1',
        'left_door_rear': '',
        'right_door_front': '',
        'right_door_rear': '',
        'left_fender': '',
        'comment': ''
    });
    
    let [imagesObj, setImagesObj] = useState<any>({});

    let listImages = () => {
        imagelistingbyscreen({
            'screen_type': 'exterior',
            "car_id": getQueryParams('cid'),
            'lead_id': getQueryParams('id')
        }).then(images => {
            if (images.data.length > 0) {
                let imageMap: any = {};
                images.data.map((image: any) => {
                    imageMap[image.image_type] = image.path;
                })
                setImagesObj({
                    ...imagesObj,
                    ...imageMap
                });
            }
        });
    }

    const handleImageUpload = async (imageType: string, edit: boolean = false) => {
        let imgData = await takePicture(edit);
        if (imgData) {
            setShowLoading(true);
            await uploadimage({
                "car_id": getQueryParams('cid'),
                "lead_id": getQueryParams('id'),
                [imageType]: imgData
            });
            setShowLoading(false);
        }
        listImages();
    };

    const handleInputs = async (e: any) => {
        let inputObj = {
            "name": e.target.name,
            "value": e.target.value
        };

        setExterior({
            ...exterior,
            [inputObj.name]: inputObj.value
        });
    }

    const submitExteriorInfo = async () => {
        setShowLoading(true);
        let updatedocs = await updateexterior(exterior);
        setShowLoading(false);
        if (updatedocs.success) {
            presentToastr(updatedocs.message, 'success');
            history.replace('/carsinfo?id=' + getQueryParams('id'));
        } else {
            presentToastr(updatedocs.message, 'danger');
        }
    }

    const getCarinfo = async () => {
        let carData: any = await carinfo(getQueryParams('id'));
        if (carData.success) {
            if (carData.data.data.length == 0) {
                presentToastr('Car not found', 'danger');
                history.goBack();
            }
            let carexterior = carData.data.data[0].cars.exterior;
            let inspectorData = JSON.parse(getData('userprofile_details'));
            setExterior({
                ...exterior,
                car_id: carData.data.data[0].cars.id,
                inspector_id: inspectorData.id
            });
            if (carexterior != null) {
                setExterior({
                    ...exterior,
                    'id': carexterior.id,
                    'car_id': carData.data.data[0].cars.id,
                    'inspector_id': inspectorData.id,
                    'right_pillar_a': parsejsonstring(carexterior.right_pillar_a),
                    'right_pillar_b': parsejsonstring(carexterior.right_pillar_b),
                    'right_pillar_c': parsejsonstring(carexterior.right_pillar_c),
                    'left_pillar_a': parsejsonstring(carexterior.left_pillar_a),
                    'left_pillar_b': parsejsonstring(carexterior.left_pillar_b),
                    'left_pillar_c': parsejsonstring(carexterior.left_pillar_c),
                    'left_apron': parsejsonstring(carexterior.left_apron),
                    'right_apron': parsejsonstring(carexterior.right_apron),
                    'boot_floor': parsejsonstring(carexterior.boot_floor),
                    'cowl_top': parsejsonstring(carexterior.cowl_top),
                    'bonnet_hood': parsejsonstring(carexterior.bonnet_hood),
                    'roof': parsejsonstring(carexterior.roof),
                    'dicky_boot_door': parsejsonstring(carexterior.dicky_boot_door),
                    'left_door_front': parsejsonstring(carexterior.left_door_front),
                    'right_fender': parsejsonstring(carexterior.right_fender),
                    'left_qtr_panel': parsejsonstring(carexterior.left_qtr_panel),
                    'right_qtr_panel': parsejsonstring(carexterior.right_qtr_panel),
                    'left_running_border': parsejsonstring(carexterior.left_running_border),
                    'right_running_border': parsejsonstring(carexterior.right_running_border),
                    'lower_cross_member': parsejsonstring(carexterior.lower_cross_member),
                    'upper_cross_member': parsejsonstring(carexterior.upper_cross_member),
                    'headlight_support': parsejsonstring(carexterior.headlight_support),
                    'headlight_support_right': parsejsonstring(carexterior.headlight_support_right),
                    'radiator_support': parsejsonstring(carexterior.radiator_support),
                    'firewall': parsejsonstring(carexterior.firewall),
                    'left_door_rear': parsejsonstring(carexterior.left_door_rear),
                    'right_door_front': parsejsonstring(carexterior.right_door_front),
                    'right_door_rear': parsejsonstring(carexterior.right_door_rear),
                    'left_fender': parsejsonstring(carexterior.left_fender),
                    'rating': carexterior.rating,
                    'comment': carexterior.comment,
                });
            }
        }
    }

    const updateRating = (rating: any) => {
        setExterior({
            ...exterior,
            'rating': rating
        });
    }

    useIonViewDidEnter(async () => {
        setShowLoading(true);
        await getCarinfo();
        setShowLoading(false);
        listImages();
    });

    return (
        <div>
            <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header pageTitle="Exterior" />
                <IonContent className="ion-padding" placeholder={undefined}>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                    />
                    <h2>Structure</h2>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Right Pillar A</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.right_pillar_a_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('right_pillar_a_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="right_pillar_a" onIonDismiss={async (event) => { handleInputs(event) }} value={exterior.right_pillar_a}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Right Pillar B</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.right_pillar_b_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('right_pillar_b_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="right_pillar_b" value={exterior.right_pillar_b} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>

                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Right Pillar C</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.right_pillar_c_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('right_pillar_c_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="right_pillar_c" value={exterior.right_pillar_c} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Right Quarter Panel</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.right_qtr_panel_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('right_qtr_panel_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="right_qtr_panel" value={exterior.right_qtr_panel} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>

                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Right Rear Door</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.right_door_rear_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('right_door_rear_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="right_door_rear" value={exterior.right_door_rear} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Right Front Door</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.right_door_front_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('right_door_front_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="right_door_front" value={exterior.right_door_front} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>

                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Right Fender</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.right_fender_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('right_fender_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="right_fender" value={exterior.right_fender} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Right Running Border</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.right_running_border_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('right_running_border_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="right_running_border" value={exterior.right_running_border} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>

                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Dicky / Boot Door</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.dicky_boot_door_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('dicky_boot_door_image', true) }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="dicky_boot_door" value={exterior.dicky_boot_door} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Boot Floor</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.boot_floor_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('boot_floor_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="boot_floor" value={exterior.boot_floor} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <h2>Ext. Panel</h2>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Left Quarter Panel</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.left_qtr_panel_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('left_qtr_panel_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="left_qtr_panel" value={exterior.left_qtr_panel} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Left Rear Door</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.left_door_rear_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('left_door_rear_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="left_door_rear" value={exterior.left_door_rear} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>

                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Left Front Door</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.left_door_front_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('left_door_front_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="left_door_front" value={exterior.left_door_front} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Left Fender</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.left_fender_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('left_fender_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="left_fender" value={exterior.left_fender} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>

                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Left Pillar A</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.left_pillar_a_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('left_pillar_a_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="left_pillar_a" value={exterior.left_pillar_a} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Left Pillar B</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.left_pillar_b_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('left_pillar_b_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="left_pillar_b" value={exterior.left_pillar_b} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>

                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Left Pillar C</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.left_pillar_c_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('left_pillar_c_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="left_pillar_c" value={exterior.left_pillar_c} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Left Running Border</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.left_running_border_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('left_running_border_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="left_running_border" value={exterior.left_running_border} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>

                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Bonnet/Hood</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.bonnet_hood_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('bonnet_hood_image', true) }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="bonnet_hood" value={exterior.bonnet_hood} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Roof</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.roof_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('roof_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="roof" value={exterior.roof} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>

                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Lower Cross Member</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.lower_cross_member_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('lower_cross_member_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="lower_cross_member" value={exterior.lower_cross_member} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repair" placeholder={undefined}>Repair</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Upper Cross Member</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.upper_cross_member_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('upper_cross_member_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="upper_cross_member" value={exterior.upper_cross_member} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repair" placeholder={undefined}>Repair</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>

                    <h2>Other Components</h2>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Headlight Support Left</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.headlight_support_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('headlight_support_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="headlight_support" value={exterior.headlight_support} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repair" placeholder={undefined}>Repair</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Headlight Support Right</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.headlight_support_right_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('headlight_support_right_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="headlight_support_right" value={exterior.headlight_support_right} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repair" placeholder={undefined}>Repair</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>

                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Radiator Support</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.radiator_support_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('radiator_support_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="radiator_support" value={exterior.radiator_support} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repair" placeholder={undefined}>Repair</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Firewall</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.firewall_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('firewall_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="firewall" value={exterior.firewall} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repair" placeholder={undefined}>Repair</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>

                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Left Apron</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.left_apron_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('left_apron_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="left_apron" value={exterior.left_apron} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Right Apron</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.right_apron_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('right_apron_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="right_apron" value={exterior.right_apron} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <IonCard className='exterior-cards' placeholder={undefined}>
                        <div>
                            <IonCardHeader className='exterior-cardheader' placeholder={undefined}>
                                <IonCardTitle className='exterior-cardtitle' placeholder={undefined}><h5>Cowl Top</h5></IonCardTitle>
                                <IonIcon className={(imagesObj.cowl_top_image != null) ? "cam-icon cameras color-success" : "cam-icon cameras"} onClick={() => { handleImageUpload('cowl_top_image') }} name="camera"></IonIcon>
                            </IonCardHeader>
                            <IonCardContent placeholder={undefined}>
                                <IonSelect className='reduced-select-width' multiple placeholder="ok" name="cowl_top" value={exterior.cowl_top} onIonDismiss={(event: any) => { handleInputs(event) }}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Original" placeholder={undefined}>Original</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Scratched" placeholder={undefined}>Scratched</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Dented" placeholder={undefined}>Dented</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Repainted" placeholder={undefined}>Repainted</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Rusted" placeholder={undefined}>Rusted</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Faded" placeholder={undefined}>Faded</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Replaced" placeholder={undefined}>Replaced</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Repaired" placeholder={undefined}>Repaired</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Damaged" placeholder={undefined}>Damaged</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Cracked" placeholder={undefined}>Cracked</IonSelectOption>
                                </IonSelect>
                            </IonCardContent>
                        </div>
                    </IonCard>
                    <div className="modal-txtarea">
                        <IonTextarea name="comment" value={exterior.comment} placeholder="Enter Remark" onIonChange={(event: any) => { handleInputs(event) }}></IonTextarea>
                    </div>
                    <Staricons rating={parseInt(exterior.rating)} handleRating={updateRating} />
                    <IonButton onClick={async () => { await submitExteriorInfo(); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
                </IonContent>
            </IonPage>
        </div>
    )
}

export default Exterior;
