import './Carprofile.css';
import { IonPage, IonContent, IonCard, IonButton, useIonViewDidEnter, IonLoading } from '@ionic/react';
import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import { useHistory } from 'react-router';
import { imagelistingbyscreen } from '../../services/camera.service';
import { useState } from 'react';
import { getQueryParams } from '../../services/helper.service';
import { takePicture, uploadimage } from '../../services/camera.service';

const Carprofile = () => {
  const history = useHistory();

  const [showLoading, setShowLoading] = useState(false);

  let [profileImages, setProfileImages] = useState<any>({
    'front_main': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'front_left_side': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'front_hood_open': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'rear_main': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'rear_right_side': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'rear_boot_open': 'https://ionicframework.com/docs/img/demos/card-media.png'
  });

  useIonViewDidEnter(() => {
    listImages();
  });

  let listImages = () => {
    imagelistingbyscreen({
      'screen_type': 'profile-picture',
      'lead_id': getQueryParams('id')
    }).then(images => {
      if (images.data.length > 0) {
        let imageMap: any = {};
        images.data.map((image: any) => {
          imageMap[image.image_type] = image.path;
        })
        setProfileImages({
          ...profileImages,
          ...imageMap
        });
      }
    });
  }

  const handleImages = async (imageType: string, edit: boolean = false) => {
    let imgData = await takePicture(edit);
    if(imgData) {
      setShowLoading(true);
      await uploadimage({
        "car_id": getQueryParams('cid'),
        "lead_id": getQueryParams('id'),
        [imageType]: imgData
      });
      setShowLoading(false);
    }
    listImages();
  };

  return (
    <div>
      <Navmenu />
      <IonPage id="main-content" placeholder={undefined}>
        <Header pageTitle="Car Profile Photo" />
        <IonContent className="ion-padding" placeholder={undefined}>
          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Loading...'}
          />
          <IonCard placeholder={undefined}>
            <h2>Front Main</h2>
            <img alt="carprofile" src={profileImages.front_main} onClick={async () => { await handleImages('front_main', true) }} />
          </IonCard>
          <IonCard placeholder={undefined}>
            <h2>Front Left Side</h2>
            <img alt="carprofile" src={profileImages.front_left_side} onClick={async () => { await handleImages('front_left_side', true) }} />
          </IonCard>
          <IonCard placeholder={undefined}>
            <h2>Front Hood Open</h2>
            <img alt="carprofile" src={profileImages.front_hood_open} onClick={async () => { await handleImages('front_hood_open', true) }} />
          </IonCard>
          <IonCard placeholder={undefined}>
            <h2>Rear Main</h2>
            <img alt="carprofile" src={profileImages.rear_main} onClick={async () => { await handleImages('rear_main', true) }} />
          </IonCard>
          <IonCard placeholder={undefined}>
            <h2>Rear Right Side</h2>
            <img alt="carprofile" src={profileImages.rear_right_side} onClick={async () => { await handleImages('rear_right_side', true) }} />
          </IonCard>
          <IonCard placeholder={undefined}>
            <h2>Rear Boot Open</h2>
            <img alt="carprofile" src={profileImages.rear_boot_open} onClick={async () => { await handleImages('rear_boot_open', true) }} />
          </IonCard>
          <IonButton onClick={() => { history.replace('/carsinfo?id=' + getQueryParams('id')); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
        </IonContent>
      </IonPage>
    </div>
  )
}

export default Carprofile