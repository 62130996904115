import './Header.css';
import IonIcon from '@reacticons/ionicons';
import { redirectifNotAuth } from '../../services/guard.service';

import {
    IonButtons,
    IonHeader,
    IonMenuButton,
    IonBackButton,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import { useHistory } from 'react-router';
import { menuController } from '@ionic/core/components';
import { getData, setData } from '../../services/localstorage.service';
import { getQueryParams } from '../../services/helper.service';
import { userprofile } from '../../services/users.services';

interface headerProps {
    pageTitle: string;
    showBackButton?: boolean;
}

function Header(props: headerProps) {
    const history = useHistory();
    
    if(redirectifNotAuth()){
        if(getQueryParams('tid') != null) {
            setData('token', getQueryParams('tid'));
            setData('aw', '1');
            userprofile().then((userprofileData)=>{
                if (userprofileData.success) {
                    setData('userprofile_details', JSON.stringify(userprofileData.data));
                    window.location.href = '/carsinfo?id='+getQueryParams('id')+'&cid='+getQueryParams('cid')
                } else {
                    history.replace('/login');
                }
            });
        } else {
            history.replace('/login');
        }
    } else {
        if(getQueryParams('tid') != null) {
            window.location.href = '/carsinfo?id='+getQueryParams('id')+'&cid='+getQueryParams('cid')
        }
    }
    
    return (
        <>
            <div>
            <IonHeader placeholder={undefined}>
                    <IonToolbar className='ins-header' placeholder={undefined}>
                    <IonButtons slot="start" placeholder={undefined}>
                        <IonMenuButton onClick={() => { menuController.enable(true); } } className={`${(props.showBackButton) ? '' : 'hide-el'}`} placeholder={undefined}></IonMenuButton>
                        <IonBackButton defaultHref="cars" className={`header-icon ${(props.showBackButton || getData('aw') == '1') ? 'hide-el' : ''}`} />
                        <IonTitle placeholder={undefined}>
                        <span className="header-title">{props.pageTitle}</span>
                        </IonTitle>
                    </IonButtons>
                    <IonIcon onClick={()=>{history.push('/profile')}} className="head-icons" name="person-circle-outline"></IonIcon>
                        <IonIcon onClick={()=>{history.push('/notifications')}} className="head-icons" name="notifications"></IonIcon>
                    </IonToolbar>
            </IonHeader>
            </div>    
        </>
    );
}
export default Header