import './Sellerdocs.css';
import { IonPage, IonContent, IonCard, IonButton, useIonViewDidEnter, IonLoading } from '@ionic/react';
import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import { useHistory } from 'react-router';
import { imagelistingbyscreen } from '../../services/camera.service';
import { useState } from 'react';
import { getQueryParams } from '../../services/helper.service';
import { takePicture, uploadimage } from '../../services/camera.service';

const Sellerdocs = () => {
  const history = useHistory();

  const [showLoading, setShowLoading] = useState(false);

  let [profileImages, setProfileImages] = useState<any>({
    'pan_card': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'adhaar_card_front': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'adhaar_card_back': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'insurance': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'rc_front': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'rc_back': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'form_26_1': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'form_26_2': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'form_26_3': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'form_28_1': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'form_28_2': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'form_29_1': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'form_29_2': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'form_30_1': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'form_30_2': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'owner_photo': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'bank_noc_1': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'bank_noc_2': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'bank_noc_3': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'voter_id': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'sale_affidavit': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'insurance_transfer': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'clearance_certificate': 'https://ionicframework.com/docs/img/demos/card-media.png',
    'moa': 'https://ionicframework.com/docs/img/demos/card-media.png',
  });

  useIonViewDidEnter(() => {
    listImages();
  });

  let listImages = () => {
    imagelistingbyscreen({
      'screen_type': 'car-documentation',
      'lead_id': getQueryParams('id')
    }).then(images => {
      if (images.data.length > 0) {
        let imageMap: any = {};
        images.data.map((image: any) => {
          imageMap[image.image_type] = image.path;
        })
        setProfileImages({
          ...profileImages,
          ...imageMap
        });
      }
    });
  }

  const handleImages = async (imageType: string, edit: boolean = false) => {
    let imgData = await takePicture(edit);
    if (imgData) {
      setShowLoading(true);
      await uploadimage({
        "car_id": getQueryParams('cid'),
        "lead_id": getQueryParams('id'),
        [imageType]: imgData
      });
      setShowLoading(false);
    }
    listImages();
  };

  return (
    <div>
      <Navmenu />
      <IonPage id="main-content" placeholder={undefined}>
        <Header pageTitle="Seller Documents" />
        <IonContent className="ion-padding" placeholder={undefined}>
          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Loading...'}
          />
          {Object.keys(profileImages).map((objkey: any, i: any) => {
            return (
              <IonCard key={"pimg" + i} placeholder={undefined}>
                <h2>{objkey}</h2>
                <img alt="carprofile" src={profileImages[objkey]} onClick={async () => { await handleImages(objkey) }} />
              </IonCard>
            );
          })}
          <IonButton onClick={() => { history.replace('/carsinfo?id=' + getQueryParams('id')); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
        </IonContent>
      </IonPage>
    </div>
  )
}

export default Sellerdocs