import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IonContent, IonPage, IonInput, IonList, IonLoading, useIonToast } from '@ionic/react';
import { Link } from "react-router-dom";
import './Resetpassword.css';
import { IonButton } from '@ionic/react';
import IonIcon from '@reacticons/ionicons';
import { resetpsw, resetpswInterface } from '../../../services/auth.service';
import { getQueryParams } from '../../../services/helper.service';

const Resetpassword: React.FC = (props: any) => {

  const [showLoading, setShowLoading] = useState(false);
  const [presentToast] = useIonToast();

  const presentToastr = (message: string, color: string) => {
    presentToast({
      message: message,
      duration: 3000,
      position: 'top',
      color: color
    });
  }

  const [resetpswDetails, setresetpswDetails] = useState<resetpswInterface>({
    email: "",
    token: "",
    password: "",
    password_confirmation: "",
  });

  useEffect(()=>{
    setresetpswDetails({
        ...resetpswDetails,
        email: getQueryParams('email')
    });
  }, []);

  const history = useHistory();

  const handleSubmit = async () => {
    setShowLoading(true)
    const resetpswRes = await resetpsw(resetpswDetails);
    setShowLoading(false);
    if(resetpswRes.success) {
      presentToastr(resetpswRes.message, 'success');
      history.push('/login');
    } else {
      presentToastr(resetpswRes.message, 'danger');
    }
  }

  return (
    <IonPage placeholder={undefined}>
      <IonContent fullscreen placeholder={undefined} >

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Loading...'}
        />

        <div className="logo"><img src="/assets/images/login.png" alt="" /></div>
        <div className='bg-container'>

          <IonList className="lgnheading" placeholder={undefined}>
            <div className="main-content">
              <div>
                <h2><b>Reset Password!</b></h2>
                <p>Please enter the OTP sent on your email.</p>
              </div>
              <div className="inputArea">
                <IonIcon name="person" />
                <IonInput clearOnEdit={true} type="email" value={resetpswDetails.email} onIonInput={(e)=>{setresetpswDetails({...resetpswDetails, email: e.target.value})}} placeholder="Enter email"></IonInput>
              </div>
              
              <div className="inputArea">
                <IonIcon name="key" />
                <IonInput type="text" value={resetpswDetails.token} onIonInput={(e)=>{setresetpswDetails({...resetpswDetails, token: e.target.value})}} placeholder="Enter OTP"></IonInput>
              </div>

              <div className="inputArea">
                <IonIcon name="lock-closed" />
                <IonInput type="password" value={resetpswDetails.password} onIonInput={(e)=>{setresetpswDetails({...resetpswDetails, password: e.target.value})}} placeholder="Enter password"></IonInput>
              </div>

              <div className="inputArea">
                <IonIcon name="lock-closed" />
                <IonInput type="password" value={resetpswDetails.password_confirmation} onIonInput={(e)=>{setresetpswDetails({...resetpswDetails, password_confirmation: e.target.value})}} placeholder="Enter confirm password"></IonInput>
              </div>
              <IonButton onClick={() => { handleSubmit(); } } className="lgnBtn" expand="block" placeholder={undefined}>RESET PASSWORD</IonButton>
              <div className="ion-text-center ion-padding">Didn't get an email?<Link to="/forgetpswd"><b> Resend OTP</b></Link></div>
            </div>
          </IonList>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Resetpassword;