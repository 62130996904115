import { getData, removeData } from './localstorage.service';

export const redirectifAuth=()=>{
    if(getData('token')!=null){
        return true;
    }
    return false;
}                               
export const redirectifNotAuth=()=>{
    if(getData('token')==null){
        removeData('token');
        return true;
    }
    return false;
}