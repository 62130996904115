import './Profile.css';

import { IonContent, IonPage, IonInput, IonButton, useIonToast, IonLoading, useIonViewDidEnter } from '@ionic/react';
import { useHistory } from 'react-router-dom';

import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import IonIcon from '@reacticons/ionicons';
import { profileupdate } from '../../services/users.services';
import { useState } from 'react';
import { userprofile } from '../../services/users.services';
import { getQueryParams } from '../../services/helper.service';


const Profile = () => {
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const [presentToast] = useIonToast();

  const presentToastr = (message: string, color: string) => {
    presentToast({
      message: message,
      duration: 3000,
      position: 'top',
      color: color
    });
  }

  const [profileDetails, setProfileDetails] = useState<any>({
    name: "",
    email: "",
    phone: "",
  });

  const handleInputs = async (e: any) => {
    let inputObj = {
      "name": e.target.name,
      "value": e.target.value
    };
    setProfileDetails({
      ...profileDetails,
      [inputObj.name]: inputObj.value
    });
  }

  const submitProfileUsers = async () => {
    setShowLoading(true);
    let updateprof = await profileupdate(profileDetails);
    setShowLoading(false);
    if (updateprof.success) {
      presentToastr(updateprof.message, 'success');
    } else {
      presentToastr(updateprof.message, 'danger');
    }
  }

  const getUserinfo = async () => {
    let userData: any = await userprofile();
    if (userData.success) {
      let users = userData.data;
      setProfileDetails({
        name: users.name,
        email: users.email,
        phone: users.phone
      });
    } else {
      presentToastr(userData.message, 'danger');
      history.goBack();
    }
  }

  useIonViewDidEnter(async () => {
    setShowLoading(true);
    await getUserinfo();
    setShowLoading(false);
  });

  return (
    <div>
      <Navmenu />
      <IonPage id="main-content" placeholder={undefined}>
        <Header pageTitle="Profile" />
        <IonContent className="ion-padding" placeholder={undefined}>
          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Loading...'}
          />
          <div className='main-content'>
            <div>
              <h2><b>My Profile</b></h2>
            </div>
            <div className="inputArea">
              <IonIcon name="person" />
              <IonInput clearOnEdit={true} name="name" value={profileDetails.name} placeholder="Enter Name" onIonChange={(event: any) => { handleInputs(event) }}></IonInput>
            </div>
            <div className="inputArea">
              <IonIcon name="mail" />
              <IonInput clearOnEdit={true} name="email" value={profileDetails.email} placeholder="Enter email" onIonChange={(event: any) => { handleInputs(event) }}></IonInput>
            </div>
            <div className="inputArea">
              <IonIcon name="call" />
              <IonInput clearOnEdit={true} name="phone" value={profileDetails.phone} placeholder="Enter Mobile Number" onIonChange={(event: any) => { handleInputs(event) }}></IonInput>
            </div>
            <IonButton onClick={async () => { await submitProfileUsers(); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
          </div>
        </IonContent>
      </IonPage>
    </div>
  )
}
export default Profile
