import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { ENV } from '../env';
import { getData } from './localstorage.service';

export const takePicture = async (edit: boolean = false) => {
  const image = await Camera.getPhoto({
    quality: 50,
    allowEditing: edit,
    source: CameraSource.Camera,
    resultType: CameraResultType.DataUrl
  });

  return image.dataUrl;
};

export const uploadimage = async (img_upload: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "upload-image/store", {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    },
    body: JSON.stringify(img_upload)
  });
  return response.json();
}

export const imagelisting = async (img_list: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "image-listing?image_type=" + img_list.image_type + "&lead_id=" + img_list.lead_id, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    }
  });
  return response.json();
}

export const imagelistingbyscreen = async (img_list: any): Promise<any> => {
  let response = await fetch(ENV.endpoint + "image-listing?screen_type=" + img_list.screen_type + "&lead_id=" + img_list.lead_id, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + getData('token')
    }
  });
  return response.json();
}