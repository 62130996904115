import { IonContent, IonPage} from '@ionic/react';
import { useHistory } from 'react-router-dom';
import './Splash.css';

const Splash: React.FC = () => {

  const history = useHistory();

  setTimeout(()=>{
    history.replace('/login');
  },1000);

  return (
    <IonPage placeholder={undefined}>
      <IonContent fullscreen placeholder={undefined}>
        <div className="Splash">
          <div className="top-img"><img src="/assets/images/img3.png" alt="" /></div>
          <div className="mid-img"><img src="/assets/images/car360logo.png" alt="" /></div>
          <div className="bot-img"><img src="/assets/images/img2.png" alt="" /></div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Splash;

