export const getQueryParams = (param: string): any => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get(param);
  };

  export const parsejsonstring = (str: any) => {
    try {
        let jsonobj = JSON.parse(str);
        return jsonobj;
    } catch (e) {
        return false;
    }
}