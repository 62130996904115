import './Steering.css';
import { 
    IonPage, 
    IonContent, 
    IonCard, 
    IonCardHeader, 
    IonSelect, 
    IonCardTitle, 
    IonSelectOption, 
    IonTextarea, 
    IonButton, 
    useIonToast,
    IonLoading,
    useIonViewDidEnter
    } from '@ionic/react';

import React, { useState } from 'react';
import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import { useHistory } from 'react-router';
import Staricons from '../../components/staricons/Staricons';
import { updatesteering, carinfo } from '../../services/cars.service';
import { getQueryParams } from '../../services/helper.service';
import { getData } from '../../services/localstorage.service';

const Steering = () => {
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);
    const [presentToast] = useIonToast();

    const presentToastr = (message: string, color: string) => {
        presentToast({
          message: message,
          duration: 3000,
          position: 'top',
          color: color
        });
      }

    const [steering, setSteering] = useState<any>({
        'id'                                    :'',
        'car_id'                                :'',
        'lead_id'                               :getQueryParams('id'),
        'inspector_id'                          :'',
        'suspension'                            :'',
        'brake'                                 :'',
        'abs'                                   :'',
        'rating'                                :'0',
        'comment'                               :'',
        'steering_and_suspension_completed'     :'1',
    })


    const handleInputs =async (e: any)=>{
        let inputObj = {
            "name": e.target.name,
            "value": e.target.value
        };
        setSteering ({
            ...steering,
            [inputObj.name]: inputObj.value
        })
    }

    const getCarinfo = async () => {
        let carData: any = await carinfo(getQueryParams('id'));
        if (carData.success) {
            if (carData.data.data.length == 0) {
                presentToastr('Car not found', 'danger');
                history.goBack();
            }
            let carsteering = carData.data.data[0].cars.steering_and_suspension;
            let inspectorData = JSON.parse(getData('userprofile_details'));
            setSteering({
                ...steering,
                car_id: carData.data.data[0].cars.id,
                inspector_id: inspectorData.id
            });
              if(carsteering != null) {
                setSteering({
                    ...steering,
                    'id'                                    :carsteering.id,
                    'car_id'                                :carData.data.data[0].cars.id,
                    'lead_id'                               :getQueryParams('id'),
                    'inspector_id'                          :inspectorData.id,
                    'suspension'                            :carsteering.suspension,
                    'brake'                                 :carsteering.brake,
                    'abs'                                   :carsteering.abs,
                    'rating'                                :carsteering.rating,
                    'comment'                               :carsteering.comment,
                });
            }
        }
    }

    useIonViewDidEnter(async ()=>{
        setShowLoading(true);
        await getCarinfo();
        setShowLoading(false);
      });

    const updateRating = (rating: any) => {
        setSteering({
            ...steering,
            'rating': rating
        });
    }

    const submitSteeringInfo = async()=>{
        setShowLoading(true);
        let updatesteeringData = await updatesteering(steering)
        setShowLoading(false);
        if(updatesteeringData.success) {
            presentToastr(updatesteeringData.message, 'success');
            history.replace('/carsinfo?id='+getQueryParams('id'));
        }else{
            presentToastr(updatesteeringData.message, 'danger');
        }
    }

  return (
    <div>
            <Navmenu />
            <IonPage id="main-content" placeholder={undefined}>
                <Header pageTitle="Steering & Suspen..." />
                <IonContent className="ion-padding" placeholder={undefined}>
                <IonLoading
                            cssClass='my-custom-class'
                            isOpen={showLoading}
                            onDidDismiss={() => setShowLoading(false)}
                            message={'Loading...'}
                        />
                <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className="airbags-cards">
                        <div className="airbag-innercards">
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Suspension</IonCardTitle></IonCardHeader>
                                <IonSelect name="suspension" value={steering.suspension} placeholder="ok" onIonDismiss={async(event: any)=>{handleInputs(event)}}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>Ok</IonSelectOption>
                                    <IonSelectOption className="color-warning" value="Abnormal" placeholder={undefined}>Abnormal Noise</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect></div>
                            <div className="airbag-innercards">
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Brake</IonCardTitle></IonCardHeader>
                                <IonSelect name="brake" value={steering.brake} placeholder="ok" onIonDismiss={async(event: any)=>{handleInputs(event)}}>
                                    <IonSelectOption className="color-success" value="Ok" placeholder={undefined}>OK</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="Not Ok" placeholder={undefined}>Not Ok</IonSelectOption>
                                </IonSelect></div>
                        </div>
                    </IonCard>
                    
                    <IonCard className="airbgcrd" placeholder={undefined}>
                        <div className="airbags-cards">
                        <div className="airbag-innercards">
                                <IonCardHeader placeholder={undefined}><IonCardTitle placeholder={undefined}>Abs</IonCardTitle></IonCardHeader>
                                <IonSelect name="abs" value={steering.abs} placeholder="ok" onIonDismiss={async(event: any)=>{handleInputs(event)}}>
                                    <IonSelectOption className="color-success" value="Yes" placeholder={undefined}>Yes</IonSelectOption>
                                    <IonSelectOption className="color-danger" value="No" placeholder={undefined}>No</IonSelectOption>
                                    <IonSelectOption value="N/A" placeholder={undefined}>N/A</IonSelectOption>
                                </IonSelect></div>
                        </div>
                    </IonCard>
                    </IonContent>
                    
                    <div className="Elec-txtarea ion-padding">
                    <div className="modal-txtarea">
                        <IonTextarea name="comment" value={steering.comment} placeholder="Enter Remark" onIonChange={(event: any)=>{handleInputs(event)}}></IonTextarea>
                    </div>
                    <Staricons rating={parseInt(steering.rating)} handleRating={updateRating}/>
                    <IonButton onClick={async () => { await submitSteeringInfo(); } } className="lgnBtn" expand="block" placeholder={undefined}>SUBMIT</IonButton>
                    </div>
                </IonPage>
    </div>
  )
}

export default Steering


