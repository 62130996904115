import { ENV } from '../env';
import { getData } from './localstorage.service';

// login Api integration
export interface loginInterface {
    email: string | number | null | undefined,
    password: string | number | null | undefined
}

export const login = async (credentials: loginInterface): Promise<any> => {
    let response = await fetch(ENV.endpoint + "login", {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    });
    return response.json();
}


//forgotpswd Api integration
export interface forgotpswInterface {
    email: string | number | null | undefined,
}

export const forgotpsw = async (credentials: forgotpswInterface): Promise<any> => {
    let response = await fetch(ENV.endpoint + "forgot-password", {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    });
    return response.json();
}


//resetpsw Api integration
export interface resetpswInterface {
    email: string | number | null | undefined,
    token: string | number | null | undefined,
    password: string | number | null | undefined,
    password_confirmation: string | number | null | undefined,
}

export const resetpsw = async (credentials: resetpswInterface): Promise<any> => {
    let response = await fetch(ENV.endpoint + "reset-password", {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    });
    return response.json();
}


//register Api integration
export interface registerInterface {
    password: string | number | null | undefined,
    user_role: number,
    is_admin : number,
    is_seller : number,
    is_buyer : number,
    is_dealer : number
    name: string | number | null | undefined,
    email: string | number | null | undefined,
    phone: string | number | null | undefined,
}
export const register = async (credentials: registerInterface): Promise<any> => {
    let response = await fetch(ENV.endpoint + "register", {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    });
    return response.json();
}

//logout Api Integreation
export interface logoutInterface{
    email: string | number | null | undefined,
    password: string | number | null | undefined
}

export const logout = async (credentials: logoutInterface): Promise<any> =>{
    let response = await fetch(ENV.endpoint + "logout", {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer '+ getData('token')
        },
        body: JSON.stringify(credentials)
    });
    return response.json();
}