import './Uploadedimages.css';
import { IonPage, IonContent, IonCard, useIonViewDidEnter, IonLoading, IonCardHeader } from '@ionic/react';
import Navmenu from '../../components/navmenu/Navmenu';
import Header from '../../components/header/Header';
import { imagelistingbyscreen } from '../../services/camera.service';
import { useState } from 'react';
import { getQueryParams } from '../../services/helper.service';
import { takePicture, uploadimage } from '../../services/camera.service';

const Uploadedimages = () => {

  const [showLoading, setShowLoading] = useState(false);

  let [profileImages, setProfileImages] = useState<any[]>([]);

  useIonViewDidEnter(() => {
    listImages();
  });

  let listImages = () => {
    setShowLoading(true);
    imagelistingbyscreen({
      'screen_type': '',
      'lead_id': getQueryParams('id')
    }).then(images => {
        setShowLoading(false);
        setProfileImages(images.data);
    }).catch((err)=>{
      setShowLoading(false);
    });
  }

  const handleImages = async (imageType: string, edit: boolean = false) => {
    let imgData = await takePicture(edit);
    if(imgData) {
      setShowLoading(true);
      await uploadimage({
        "car_id": getQueryParams('cid'),
        "lead_id": getQueryParams('id'),
        [imageType]: imgData
      });
      setShowLoading(false);
    }
    listImages();
  };

  return (
    <div>
      <Navmenu />
      <IonPage id="main-content" placeholder={undefined}>
        <Header pageTitle="Review Images" />
        <IonContent className="ion-padding" placeholder={undefined}>
          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Loading...'}
          />
          {profileImages.map((image)=>{
            if(image.image_type == 'sound_video_image') {
              return (
                <IonCard key={image.id} placeholder={undefined}>
                  <IonCardHeader placeholder={undefined}>{image.image_type}</IonCardHeader>
                  <video width={'100%'} height={'auto'} controls>
                    <source src={image.path}></source>
                  </video>
                </IonCard>
              )
            } else {
              return (
                <IonCard key={image.id} placeholder={undefined}>
                  <IonCardHeader placeholder={undefined}>{image.image_type}</IonCardHeader>
                  <img alt="carprofile" src={image.path} onClick={async () => { await handleImages(image.image_type, true) }} />
                </IonCard>
              )
            }
          })}
        </IonContent>
      </IonPage>
    </div>
  )
}

export default Uploadedimages