import React, { useState } from 'react';
import { IonContent, IonPage, IonInput, IonList, IonCheckbox, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, useIonToast, IonLoading } from '@ionic/react';
import './Register.css';
import { Link } from "react-router-dom";
import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import IonIcon from '@reacticons/ionicons';
import { register, registerInterface } from '../../../services/auth.service';
import { redirectifAuth } from '../../../services/guard.service';

const Register: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [presentToast] = useIonToast();

  const presentToastr = (message: string, color: string) => {
    presentToast({
      message: message,
      duration: 3000,
      position: 'top',
      color: color
    });
  }
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const history = useHistory();
  
  if(redirectifAuth()){
    history.replace('/cars');
  }

  const [registerDetails, setRegisterDetails] = useState<registerInterface>({
    name: "",
    email: "",
    phone: "",
    password: "",
    user_role: 12,
    is_admin : 1,
    is_seller : 0,
    is_buyer : 0,
    is_dealer : 0
  });

  const handleSubmit = async () => {
    setShowLoading(true)
    const registerRes = await register(registerDetails);
    setShowLoading(false);
    if (registerRes.success) {
      presentToastr(registerRes.message, 'success');
      history.replace('/login');
    } else {
      presentToastr(registerRes.message, 'danger');
    }
  }
  return (
    <IonPage placeholder={undefined}>
      <IonContent fullscreen placeholder={undefined} >
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Loading...'}
        />
        <div className="logo"><img src="/assets/images/signup.logo.png" alt="" /></div>
        <div className='bg-container'>
          <IonList className="signheading" placeholder={undefined}>
            <div className='main-content'>
              <div>
                <h2><b>Welcome back!</b></h2>
                <p>Hello there, login to continue</p>
              </div>
              <div className="inputArea">
                <IonIcon name="person" />
                <IonInput clearOnEdit={true} type="text" value={registerDetails.name} onIonInput={(e)=>{setRegisterDetails({...registerDetails, name: e.target.value})}} placeholder="Enter Name"></IonInput>
              </div>
              <div className="inputArea">
                <IonIcon name="call" />
                <IonInput clearOnEdit={true} type="text" value={registerDetails.phone} onIonInput={(e)=>{setRegisterDetails({...registerDetails, phone: e.target.value})}} placeholder="Enter Mobile Number"></IonInput>
              </div>
              <div className="inputArea">
                <IonIcon name="mail" />
                <IonInput clearOnEdit={true} type="email" value={registerDetails.email} onIonInput={(e)=>{setRegisterDetails({...registerDetails, email: e.target.value})}} placeholder="Enter email"></IonInput>
              </div>
              <div className="inputArea">
                <IonIcon name="lock-closed" />
                <IonInput type="password" value={registerDetails.password} onIonInput={(e)=>{setRegisterDetails({...registerDetails, password: e.target.value})}} placeholder="Enter Password"></IonInput>
              </div>
              <div>
                <p>By Completing and Submitting this from you agree to the terms and conditions</p>
                <div className='auth-terms-check'>
                  <IonCheckbox slot="start" placeholder={undefined}></IonCheckbox>
                  <span> I agree to the <a href="javascript:void(0)" onClick={() => setIsTermsModalOpen(true)}><b>terms and conditions</b></a></span>
                </div>
              </div>
              <IonButton onClick={() => { handleSubmit(); } } className="lgnBtn" expand="block" placeholder={undefined}>Sign Up</IonButton>
              <div className="ion-text-center ion-padding">Already have an account?<Link to="/login"><b> Login</b></Link></div>
            </div>
          </IonList>
        </div>
      </IonContent>
      <IonModal isOpen={isTermsModalOpen} placeholder={undefined}>
        <IonHeader placeholder={undefined}>
          <IonToolbar placeholder={undefined}>
            <IonTitle placeholder={undefined}>Terms and Condition</IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              <IonButton onClick={() => setIsTermsModalOpen(false)} placeholder={undefined}>CLOSE</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding" placeholder={undefined}>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni illum quidem recusandae ducimus quos
            reprehenderit. Veniam, molestias quos, dolorum consequuntur nisi deserunt omnis id illo sit cum qui.
            Eaque, dicta.
          </p>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default Register;